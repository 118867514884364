.static-header.auth {
	margin-bottom: 24px;
}
.auth-box {
	border: 1px solid $white;
	padding: 24px 36px;
	height: 100%;
}
.auth-features {
	padding-top: 12px;
}
.auth-features li {
	line-height: 36px;
	height: 36px;
	margin-top: 12px;
	i.fa {
		vertical-align: -4px;
		margin-right: 24px;
		color: $default;
	}
}
@mixin submit-button(){
	cursor: pointer;
	text-transform: uppercase;
	@include montserrat-bold();
	@include transition();
	font-size: 14px;
	padding: 8px 24px;
    height: 48px;
	border-radius: 6px;
	width: 100%;
}
.btn-auth-primary {
	@include submit-button();
	background: $default;
	border: 2px solid $default;
	color: black;
	&:hover:not([disabled=disabled]) {
		background: transparent!important;
		color: $default;
	}
}
.btn-auth-secondary {
	@include submit-button();
	background: transparent;
	border: 2px solid $white;
	color: $white;
	margin-top: 48px;
	line-height: 28px;
	&:hover {
		background: $white!important;
		color: $black!important;
	}
}
.auth-reset {
	margin: 12px auto;
	float: right;
	font-weight: bold;
}
.social-login {
	padding: 46px 0 0;
}
.social-login .btn {
	margin: 6px;
	color: $white!important;
	text-transform: uppercase;
	@include montserrat-bold();
	font-size: 14px;
	padding: 6px;
	border-radius: 6px;
	&.btn-facebook {
		background: #3b5998;
	}
	&.btn-twitter {
		background: #1da1f2;
	}
	&.btn-google {
		background: #dd4b39;
	}
	@include transition(all, 0.3s, ease-out);
	&:hover {
		cursor: pointer;
		text-decoration: none!important;
		box-shadow: 0px 6px 25px 0px rgba(0,0,0,0.3);
		div {
			text-decoration: underline!important;
		}
	}
	div {
		text-align: left;
		margin-left: 12px;
	}
}
