// Bootstrap "sm"
@media only screen and (max-width: 767px){
	.invisible {
		display: none;
	}
	.modal.show .modal-dialog {
		transform: none;
	}
	.modal-dialog {
		padding: 0;
		margin: 0;
	}
	.modal-content {
		padding: 24px;
	}
	.modal-header {
		padding: 6px;
	}
	.modal#event {
		.col-12 {
			padding: 0;
		}
		h5.col-12 {
			padding-left: 12px;
		}
		.teams, .staff {
			padding-bottom: 24px;
		}
		.details {
			padding: 12px;
			text-align: center;
			h5.col-12 {
				padding-left: 0;
			}
			div {
				padding-bottom: 12px;
				margin-top: 12px;
			}
		}
		.row:first-child .col-12 {
			margin-bottom: -12px;
		}
		.staff-container, .team-container {
			padding-top: 24px;
		}
	}
	.col-12.header-title {
		h1, h2 {
			text-align: center;
			margin-right: 0;
		}
	}
	.table-header {
		.tag {
			font-size: 24px;
			&.vs {
				font-size: 18px;
			}
		}
		.text-emphasis {
			display: inline-block;
			margin-top: 24px;
		}
	}
	p.message {
		margin: 24px auto;
	}

}
//Bootstrap "xs"
@media only screen and (max-width: 575px){
	.contents .container, .header.container, footer.page-footer .container {
		padding: 24px!important;
	}
	.modal-splash,.modal-content {
		padding: 12px!important;
	}
	.form-group.fit {
		margin-bottom: 18px!important;
	}
	.social-login {
		margin: 0 auto;
	}
	.header.container {
		width: 100%;
	}
	p.message {
		margin: 12px auto;
	}
	footer.page-footer .container {
		padding: 12px 24px 24px 24px;
		.col-title {
			border: 0;
			font-weight: normal!important;
			padding-top: 12px;
		}
		.quick-links a {
			display: inline-block;
			width: 50%;
		}
		.patreon a {
			opacity: 1;
		}
	}
}
// XXS?
@media only screen and (max-width: 490px){

	.static-page .card-block {
		padding: 24px;
	}
	.events-row {
		justify-content: center!important;
	}
	.logo, .navigation-logo {
		width: 149.8px;
    	height: 36.4px;
	}
	.btn-auth {
		height: 21px;
		line-height: 17px;
		font-size: 12px;
		padding: 0 6px;
		margin: 6px 0!important;
		display: block;
	}
	nav.navigation {
		margin-top: -5px;
		> div {
			text-align: center;
		}
	}
	.hero {
		padding: 0;
		flex: 0 0 83.333333%;
    	max-width: 83.333333%;
	}
	.hero-title {
		margin-bottom: 36px;
		margin-top: 0;
		font-size: 18px;
	}
	.event-controls {
		button, .dropdown-item {
			font-size: 12px!important;
		}
	}
	.table-header {
		.tag {
			font-size: 18px;
			&.vs {
				font-size: 12px;
			}
		}
		.text-emphasis {
			display: inline-block;
			margin-top: 24px;
		}
	}
}
