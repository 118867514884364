.static-header {
	margin: 36px auto;
}
.text-columns {
	column-count: 2;
	column-gap: 60px;
}
.img-fluid.svg-50 {
	width: 50%;
}
.static-intro {
	margin-bottom: 36px;
	line-height: 2;
}
.team-member {
	img {
		margin: 24px auto;
	}
	h2 {
		text-align: left;
		letter-spacing: 1px;
		margin-bottom: 6px;
	}
	p {
		margin-top: 12px;
	}
	&.recruit-member img {
		filter: grayscale(100%);
	}
}
.static-last {
	margin-bottom: 48px;
}
.promote {
	font-size: 18px;
	blockquote {
		font-size: 18px;
	}
}
.faq {
	.col-12.col-sm-6 {
		margin-bottom: 24px;
	}
}
// old v
.static-page {
	h1 {
		font-weight: bold;
		margin-bottom: 24px;
	}
	.card {
		.lead {
			font-size: 18px;
			font-weight: normal
		}
		background: transparent;
		& > .card-block {
			border: 2px solid $default-60;
			& + .card-block {
				border-top: 0;
			}
		}
		& > .row {
			border: 2px solid $default-60;
			border-top: 0;
			.img-fluid {
				max-width: 75%;
			}
			.card-block {
				padding: 24px;
				text-align: center;
				&:first-child {
					padding: 0;
				}
			}
			&.author {
				.img-fluid {
					max-width: 85%;
					max-height: 100px;
					margin: 12px auto;
				}
				.card-title {
					margin: 12px auto auto 0;
				}
				.card-block {
					padding: 0;
				}
				.card-text {
					margin: 12px 24px 24px 0;
					text-align: left;
				}
			}
			& + .card-block {
				border-top: 0;
			}
		}
	}
	.card-header, .card-footer {
		background: $default-60;
		text-align: left;
	}
	h2.card-title {
		text-align: left;
	}
	h3.card-title {
		@include montserrat-bold();
		font-size: 24px;
	}
	h4.card-title {
		@include montserrat-regular();
		font-size: 18px;
	}
	.card-block {
		background: $default-dark;
		padding: 48px;
		.btn {
			@include transition();
			background: $default-60;
			border: 2px solid transparent!important;
			color: white!important;
			box-shadow: none!important;
			&:hover {
				border: 2px solid $default-60!important;
				text-decoration: underline;
			}
		}
		&.team-desc {
			border-bottom: 2px $default-dark;
		}
	}
	.card-header {
		border-bottom: 0;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
	.card-footer {
		border-top: 0;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
		color: $white-60;
		font-weight: normal;
	}
}
.article p:not(.lead){
	letter-spacing: .25px;
}