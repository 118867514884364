.event-header {
	align-items: stretch;
	margin-bottom: 24px;
}

.img-fluid {
	pointer-events: none;
}

.header-title {
	display: flex;
	justify-content: center;
	flex-direction: column;
	h1,
	h2 {
		text-align: left;
		margin-bottom: 0;
		overflow-wrap: break-word;
		margin-right: 48px;
	}
	h1 {
		@include montserrat-bold();
	}
	h2 {
		@include montserrat-light();
	}
}

.event-overview {
	@include montserrat-light();
	width: 100%;
	border-radius: 6px;
	border-spacing: 0 .1rem;
	border-collapse: separate;
	tbody {
		tr:last-child {
			td:first-child {
				border-bottom-left-radius: 6px;
			}
			td:last-child {
				border-bottom-right-radius: 6px;
			}
		}
	}
}

.event-controls {
	.col-12,
	.col-6 {
		margin-bottom: 12px;
	}
}

.module-title {
	text-align: left;
	font-weight: bold;
	small {
		font-size: inherit;
		font-weight: 300;
		&:not(:first-child){
			margin-left: 12px;
		}
	}
}
.module-title, .match-title {
	margin-bottom: 12px;
}
.match-title {
	text-align: left;
	@include montserrat-bold();
	font-size: 14px;
}
.opacity-text {
	color: $white-60!important;
	&[ng-click]:hover {
		color: white!important;
	}
}
.table-row {
	transition: background-color 0.3s linear, border-color 0.3s linar;
	&:not(.table-header) {
		border-left: 2px solid $default-60;
		border-right: 2px solid $default-60;
		@include colourise-from-parent(border-color, 60, !important);
	}
	&.inner {
		border-top: 2px solid transparent;
		&.watched .col-6 {
			opacity: 0.5;
		}
	}
	.col-2, .col-md-2 {
		flex-grow: 1;
		max-width: none;
	}
	margin: 0;
	text-align: center;
	padding: 12px;
	.lol & {
		background-color: rgba($lol-dark, 0.6)!important;
	}
	.csgo & {
		background-color: rgba($csgo-dark, 0.6)!important;
	}
	.dota & {
		background-color: rgba($dota-dark, 0.6)!important;
	}
	.overwatch & {
		background-color: rgba($ow-dark, 0.6)!important;
	}
	.rocket-league & {
		background-color: rgba($rl-dark, 0.6)!important;
	}
	.pubg & {
		background-color: rgba($pubg-dark, 0.6)!important;
	}
	&.titles {
		@include montserrat-bold();
		font-size: 18px;
	}
	a[ng-href] {
		display: inline-block;
		opacity: 0.6;
		&:hover {
			opacity: 1;
			cursor: pointer;
		}
	}
	.mobile-header {
		display: inline-block;
		@include montserrat-bold();
		font-size: 12px;
		color: $white;
	}
	.mb-1, .mb-md-0, .mt-2 {
		display: inline-block;
	}
	.row.two-icons {
		justify-content: center;
		margin: 0 initial;
		.col-4 {
			padding: 0;
		}
	}
}

.table-header {
	@include montserrat-bold();
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	padding: 0 6px;
	min-height: 60px;
	border-color: transparent!important;
	&.collapsed {
		border-radius: 6px;
		margin-bottom: 24px;
	}
	text-align: center;
	background: $default-60!important;
	&.watched.collapsed {
		opacity: 0.5;
	}
	@include colourise-from-parent(background-color, 60, !important);
	.tag,
	.tag a {
		word-break: break-word;
	}
	.tag {
		display: inline-block;
		font-size: 24px;
		&.col-md-2 {
			font-size: 18px;
		}
	}
	&.collapsed a.expanded-only {
		pointer-events: none;
	}
}

.table-footer {
	height: 42px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-transform: uppercase;
	@include montserrat-bold();
	padding: 0 12px;
	margin-bottom: 24px;
	background: $default-60!important;
	@include colourise-from-parent(background, 60, !important);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	a.text-emphasis, h6 {
		img {
			position: relative;
			top: 3px;
			vertical-align: baseline;
		}
	}
	a.text-emphasis img {
		padding-left: 12px;
	}
}

.text-emphasis {
	display: inline-block;
	@include montserrat-bold();
	text-transform: uppercase;
	font-size: 14px;
	color: $white;
	&.watched {
		letter-spacing: 1px;
		img {
			position: relative;
			top: -1px;
			padding-right: 6px;
		}
	}
}

.section-nav {
	@include montserrat-bold();
	font-size: 18px;
	text-transform: uppercase;
	margin-top: 24px;
}

.ratings {
	font-size: 90%;
	i:hover {
		@include transition();
		cursor: pointer;
		transform: translateY(-2px);
	}
	&.readonly i {
		pointer-events: none;
	}
}

.match-icon {
	height: 36px;
	margin: 0 6px;
	pointer-events: none;
}

.team-icon {
	max-height: 48px;
	max-width: 100%;
	margin: 6px auto;
	pointer-events: none;
}

.event-teams {
	font-size: 1.5rem;
}

.caret-down {
	padding-right: 24px;
	background: url('/assets/images/icons/caret-down.svg');
	background-repeat: no-repeat;
	background-position: right 7px;
}

.caret-up {
	padding-right: 24px;
	background: url('/assets/images/icons/caret-up.svg');
	background-repeat: no-repeat;
	background-position: right 7px;
}
