@mixin slider() {
	-webkit-appearance: none;
	margin: 0 6px;
	cursor: pointer!important;
	&:focus {
		outline: none;
	}
	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 1.4px;
		box-shadow: 0px 0px 0.4px #002200, 0px 0px 0px #003c00;
		background: #ffffff;
		border-radius: 0px;
		border: 0px solid #ffffff;
	}
	&::-webkit-slider-thumb {
		box-shadow: 0px 0px 0px #00aa00, 0px 0px 0px #00c300;
		border: 0px solid #83e584;
		height: 12px;
		width: 12px;
		border-radius: 6px;
		background: #ffffff;
		-webkit-appearance: none;
		margin-top: -5.4px;
	}
	&:focus::-webkit-slider-runnable-track {
		background: #ffffff;
	}
	&::-moz-range-track {
		width: 100%;
		height: 1.4px;
		box-shadow: 0px 0px 0.4px #002200, 0px 0px 0px #003c00;
		background: #ffffff;
		border-radius: 0px;
		border: 0px solid #ffffff;
	}
	&::-moz-range-thumb {
		box-shadow: 0px 0px 0px #00aa00, 0px 0px 0px #00c300;
		border: 0px solid #83e584;
		height: 12px;
		width: 12px;
		border-radius: 6px;
		background: #ffffff;
	}
	&::-ms-track {
		width: 100%;
		height: 1.4px;
		border: 0px solid transparent;
		background: transparent;
		color: transparent;
	}
	&::-ms-fill-lower {
		background: #fcfcfc;
		border: 0px solid #ffffff;
		border-radius: 0px;
		box-shadow: 0px 0px 0.4px #002200, 0px 0px 0px #003c00;
	}
	&::-ms-fill-upper {
		background: #ffffff;
		border: 0px solid #ffffff;
		border-radius: 0px;
		box-shadow: 0px 0px 0.4px #002200, 0px 0px 0px #003c00;
	}
	&::-ms-thumb {
		box-shadow: 0px 0px 0px #00aa00, 0px 0px 0px #00c300;
		border: 0px solid #83e584;
		height: 12px;
		width: 12px;
		border-radius: 6px;
		background: #ffffff;
		margin-top: 0;
	}
	&:focus::-ms-fill-lower {
		background: #ffffff;
	}
	&:focus::-ms-fill-upper {
		background: #ffffff;
	}
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	// IE10 / 11
	&::-ms-track {
		margin-top: 13.4px;
		border-width: 5.4px 0;
	}
}
