.modal#event {
	.modal-content {
		@include transition();
		@include colourise-from-parent(background, dark, !important);
	}
	.details {
		.row:first-child {
			padding-bottom: 0;
		}
		div {
			margin-top: 24px;
			padding-bottom: 24px;
		}
		a {
			color: $default;
			@include transition();
			@include colourise-from-parent(color, default, !important);
		}
	}
	.staff, .teams {
		padding-bottom: 48px;
		h5 {
			margin-bottom: 0;
		}
	}
	.staff-container, .team-container {
		padding-top: 36px;
	}
	.staff a.staff-photo, .teams a.team-icon {
		display: block;
		width: 48px;
		text-align: center;
		img {
			max-width: 48px;
			pointer-events: none;
		}
	}
	a.staff-info, a.team-info {
		float: left;
	}
	.team-info {
		font-weight: bold;
	}
	.event-details__staff-alias {
		&::before {
			content: '"';
			@include montserrat-light();
		}
		&::after {
			content: '"';
			@include montserrat-light();
		}
		@include montserrat-bold();
	}
}


