.custom-player {
	&.theatre-mode #player {
		margin: auto;
		.video-container {
			margin-top: 4px;
		}
	}
	&.theatre-mode .player-card .card-block {
		border-radius: 0!important;
	}
	&.theatre-mode .container {
		padding: 0;
	}
	.text-emphasis {
		font-size: 14px;
		text-transform: capitalize;
	}
	.player-card {
		background: transparent;
		border: 0;
		.card-footer,
		.card-header {
			background: transparent;
			border: 0;
		}
		.card-header {
			padding: 0 0 24px 0;
			@include montserrat-bold();
			.match-event h2 {
				text-align: left;
				margin-bottom: 0;
				&.light {
					margin-top: 2px;
					font-weight: 300;
				}
			}
			.player-index {
				float: right;
				text-align: left;
				span {
					display: inline-block;
				}
				a, span {
					margin-top: 6px;
				}
			}
			.col {
				text-align: center;
				font-size: 14px;
				line-height: 18px;
				&.vs {
					flex-grow: 0;
					padding: 0 24px;
				}
				&.col-icon a {
					height: 48px;
					display: inline-block;
					> img {
						margin: 0;
					}
				}
				&.next img, &.return img {
					position: relative;
					top: -1px;
					padding: 0 6px;
				}
			}
		}
		.card-footer {
			border-top: 0;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
			padding: 0;
			&.controls-row {
				padding-top: 24px;
				padding-bottom: 12px;
			}
			.player-jumps a {
				font-weight: bold;
				margin-right: 36px;
				display: inline-block;
				text-transform: uppercase;
				max-width: 56px;
				text-align: center;
				vertical-align: middle;
			}
			.player-controls {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.btn-skip {
					@include montserrat-bold();
					img {
						position: relative;
						left: 2px;
					}
				}
				.btn-toggle {
					border-radius: 50%;
					border: 2px solid $default;
					width: 48px;
					height: 48px;
					@include transition();
					img {
						position: relative;
						z-index: 2;
						width: 44px;
						height: 44px;
					}
					@include colourise-from-parent(border-color);
					&.fill {
						@include colourise-from-parent(background-color);
					}
				}
			}
			.player-actions {
				img, input, span {
					margin-left: 24px;
				}
				span {
					@include montserrat-bold();
					font-size: 18px;
					vertical-align: middle;
				}
				.dropup {
					display: inline-block;
					img {
						cursor: pointer;
					}
					.dropdown-menu {
						border-radius: 6px;
					}
				}
				input {
					margin-left: 0 0 0 6px;
					vertical-align: middle;
					width: 85px;
					@include slider();
				}
			}
			.player-share {
				a {
					display: block;
					&:hover {
						text-decoration: none!important;
						h2 {
							text-decoration: underline!important;
						}
					}
				}
				h2 {
					display: inline;
					text-align: right;
					margin-right: 12px;
					vertical-align: middle;
				}
				img {
					width: 30px;
				}
			}
		}
		.card-block {
			border: 2px solid $default;
			background: $default-dark;
			color: $default;
			border-radius: 6px!important;
			box-sizing: content-box;
			overflow: hidden;
			@include transition();
			@include colourise-from-parent(border-color);
			@include colourise-from-parent(color);
			@include colourise-from-parent(background, dark);
		}
		.card-block#player {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			.text-warning {
				@include montserrat-bold();
				font-size: 18px;
			}
		}
	}
	#player {
		position: relative;
		.blocker {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 27%;
			width: 100%;
			background: black;
		}
	}
}
