nav.navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	div {
		width: fit-content;
		text-align: right;
	}
}


