footer.page-footer {
	margin-top: 0;
    color: $white;
    background: $black;
	padding-top: 12px;
	position: relative;
	.container {
		padding: 24px 0;
	}
	.col-title, .col-12 a {
		@include montserrat-bold();
		padding-bottom: 12px;
	}
	.col-title {
		font-size: 18px;
		line-height: 24px;
		border: 0;
		border-bottom: 2px solid $default;
		@include transition();
		@include colourise-from-parent(border-color, default, !important);

	}
	.col-12 a {
		display: block;
		width: 100%;
		&:first-of-type {
			padding-top: 6px;
		}
	}
	.quick-links a, .social-media a {
		font-size: 14px;
		img {
			max-height: 1em;
			pointer-events: none;
			position: relative;
			top: -1px;
		}
	}
	.social-media a i:last-child {
		font-style: normal;
	}
	.social-media a:hover {
		text-decoration: none;
		i:last-child {
			text-decoration: underline;
		}
	}
	.patreon a, .social-media-mob a {
		padding-top: 6px;
	}
	.patreon a {
		opacity: 0.6;
		max-width: 50%;
		&:hover {
			opacity: 1;
		}
	}
    .footer-copyright {
		align-items: flex-start;
        letter-spacing: 0.5px;
		font-size: 12px;
		margin-top: 24px;
		padding-top: 12px;
        border-top: 2px solid $default;
		@include montserrat-regular();
		@include transition();
		@include colourise-from-parent(border-color, default, !important);
		.col-12 {
			opacity: 0.6;
			padding-right: 12px;
			&.text-right {
				padding: 0;
				opacity: 1;
				font-size: 14px;
			}
		}
    }
}
.skip-to-top {
	position: absolute;
    top: 36px;
    right: 84px;
	.rel-container {
		position: absolute;
		display: inline-block;
		height: 36px;
		width: 36px;
		opacity: 0.4;
		transform: all 0.5s linear;
		&:hover {
			opacity: 1;
			img {
				transform: rotate(-90deg);
			}
		}
		img {
			height: 36px;
			width: 36px;
			pointer-events: none;
		}
	}
}
