@mixin error-input(){
	border-color: $eventvods-error!important;
}
@mixin error-desc(){
	@include montserrat-bold();
	transform: translateY(0%);
	opacity: 1;
	pointer-events:	auto;
}

.alert {
	border-radius: 0!important;
}

.search-field input[type=text] {
    @include montserrat-bold();
    background: 0;
    width: 100%;
    border: 0;
    display: block;
    position: relative;
    outline: 0;
    color: $white;
    padding: .5rem 0;
    &::placeholder {
        color: $white;
    }
}
.sort-field {
	float: right;
}
.sort-field select {
    outline: 0;
    @include montserrat-bold();
    width: 100%;
    color: $white;
    border: 2px solid $default;
    background: 0;
    text-transform: uppercase;
    padding: .5rem 0;
}
.form-control {
	font-size: 14px;
}
.form-group {
	position: relative;
	height: 50px;
	margin-bottom: 12px;
	&.fit {
		height: fit-content;
		margin-bottom: 24px!important;
		span.error {
			margin-top: 7px;
			position: absolute;
			color: $eventvods-error;
			@include transition();
			opacity: 0;
			transform: translateY(50%);
			pointer-events: none;
		}
		.ng-invalid.ng-touched {
			@include error-input();
		}
		.ng-invalid.ng-touched + span.error {
			@include error-desc();
		}
	}
	label, input {
		position: absolute;
		left: 0;
		top: 0;
		color: $white!important;
		font-size: 14px;
	}
	label {
		display: block;
		width: 100%;
		padding: .5rem .75rem .5rem 0;
		line-height: 1.25;
		pointer-events: none;
		@include transition(all, 0.3s, ease-in-out);
		@include montserrat-bold();
	}
	input {
		@include montserrat-bold();
		background: transparent!important;
		border: 0!important;
		border-bottom: 2px solid $white!important;
		z-index: 3;
		padding-left: 0;
		color: $white;
		border-radius: 0!important;
	}
	input:focus + label, input.ng-not-empty + label, input.ng-valid:not(.ng-empty) + label {
		font-size: 12px;
		transform: translateY(-60%);
		color: $white-40;
	}
	div {
		position: absolute;
		top: 42px;
		color: $eventvods-error!important;
		@include transition();
		opacity: 0;
		transform: translateY(50%);
		pointer-events: none;
	}

	// Not sure if this is a work of art, or
	// spawn of satan
	input:not([type="password"]):not(:focus).ng-invalid:not(.ng-empty):not(.ng-invalid-unique-email):not(.ng-invalid-unique-name),
	input:not([type="password"]):not(:focus).ng-invalid-required.ng-touched,
	{
		@include error-input();
		& + label + div.required,	// Required only
		& + label + div + div.required { // Other validation + required
			@include error-desc();
		}
	}
	input[type="password"].ng-invalid-pattern:not(.ng-empty) {
		@include error-input();
		& + label + div.valid {		// Invalid or not strong enough password
			@include error-desc();
		}
	}
	input[type="password"]:not(:focus).ng-invalid-required.ng-touched.ng-valid-pattern {
		@include error-input();
		& + label + div + div.required {
			@include error-desc();
		}
	}
	#login & input[type="password"].ng-invalid-required.ng-touched {
		@include error-input();
		& + label + div.required {
			@include error-desc();
		}
	}
	#login & input[type="password"].invalid-pass {
		@include error-input();
		& + label + div.required + div.invalid {
			@include error-desc();
		}
	}
	#register & input[type="email"].ng-invalid-unique-email.ng-touched,
	#register & input[type="text"].ng-invalid-unique-name.ng-touched {
		@include error-input();
		& + label + div.conflict {
			@include error-desc();
		}
	}
}
label.ev-checkbox {
	cursor: pointer;
	margin: 4px 0;
	input {
		opacity: 0;
		pointer-events: none;
		position: absolute;
	}
	div.flex-center {
		position: relative;
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		font-size: 18px;
		margin-top: 1px;
		border-radius: 6px;
		background: $white;
		color: $black;

	}
	p {
		margin: initial;
		padding-left: 24px;
	}
}

.form div.col-6, .form div.col-12 {
	margin-top: 24px;
}
.form div.col-12.row {
	margin-top: 0;
}
.form .filler {
	margin: 18px 0;
}

