// Element background count
$element-images: 29;

/* Color palette */

$white: #ffffff;
$black: #1d1d1d;
$white-10: rgba(255, 255, 255, 0.1);
$white-40: rgba(255, 255, 255, 0.4);
$white-60: rgba(255, 255, 255, 0.6);
$default: #00db7b;
$default-dark: #192129;
$default-60: rgba(0,219,123,0.6);
$lol: #00b4b1;
$lol-60: rgba(0, 180, 177, 0.6);
$lol-30: rgba(0, 180, 177, 0.3);
$lol-dark: #123235;
$dota: #df2700;
$dota-60: rgba(223, 39, 0, 0.6);
$dota-30: rgba(223, 39, 0, 0.3);
$dota-dark: #2d0e0d;
$csgo: #33a3d4;
$csgo-60: rgba(51, 163, 212, 0.6);
$csgo-30: rgba(51, 163, 212, 0.3);
$csgo-dark: #112041;
$ow: #FF8900;
$ow-60: rgba(255, 137, 0, 0.6);
$ow-30: rgba(255, 137, 0, 0.3);
$ow-dark: #2c150a;
$pubg: #ffe242;
$pubg-60: rgba(255, 226, 66, 0.6);
$pubg-30: rgba(255, 226, 66, 0.3);
$pubg-dark: #0b1a2a;
$rl: #a075e4;
$rl-60: rgba(160, 117, 228, 0.6);
$rl-30: rgba(160, 117, 228, 0.3);
$rl-dark: #002416;
$valorant: #FF4655;
$valorant-30: rgba(255,70,85, .3);
$valorant-60: rgba(255,70,85, .6);
$valorant-dark: #0F1923;
$eventvods-error: $default;

$game-colors: (
	lol: (default: #00b4b1, 30: rgba(0, 180, 177, 0.3), 60: rgba(0, 180, 177, 0.6), dark: #123235),
	dota: (default: #df2700, 30: rgba(223, 39, 0, 0.3), 60: rgba(223, 39, 0, 0.6), dark: #2d0e0d),
	csgo: (default: #33a3d4, 30: rgba(51, 163, 212, 0.3), 60: rgba(51, 163, 212, 0.6), dark: #112041),
	overwatch: (default: #FF8900, 30: rgba(255, 137, 0, 0.3), 60: rgba(255, 137, 0, 0.6), dark: #2c150a),
	rocket-league: (default: #a075e4, 30: rgba(160, 117, 228, 0.3), 60: rgba(160, 117, 228, 0.6), dark: #002416),
	valorant: (default: #FF4655, 30: rgba(255,70,85, .3), 60:rgba(255,70,85, .6), dark: #0F1923),
);
@mixin colourise-from-parent ($param, $colorset:"default", $important:null){
  @each $class, $map in $game-colors {
    .#{$class} & {
      #{$param}: map-get($map, $colorset)$important;
    }
  }
}
@mixin colourise-from-self ($param, $colorset:"default", $important:null){
  @each $class, $map in $game-colors {
    &.#{$class} {
      #{$param}: map-get($map, $colorset)$important;
    }
  }
}

@mixin montserrat-light() {
    font-family: Montserrat!important;
    font-weight: 300!important;
}

@mixin montserrat-regular() {
    font-family: Montserrat!important;
    font-weight: 400!important;
}

@mixin montserrat-bold() {
    font-family: Montserrat!important;
    font-weight: 700!important;
}

@mixin transition($param: all, $time: 0.3s, $function: linear) {
    transition: $param $time $function;
}

/* Event progress bar */
@keyframes loadBar {
  0% {
	opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
	opacity: 1;
  }
}


@mixin logo-animation(){

	animation-iteration-count: infinite;

	animation-duration: 2500ms;

	animation-timing-function: cubic-bezier(0.685, 0.080, 0.245, 0.855);

	height: 50px;

	transform: translate3d(0,0,0);

}

@keyframes logo-ev {
	0% {
		opacity: 1;
	}

	17% {
		opacity: 0;
	}

	34% {
		opacity: 0;
	}

	51% {
		opacity: 0;
	}

	68% {
		opacity: 0;
	}

	85% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes logo-lol {

	0% {
		opacity: 0;
	}

	17% {
		opacity: 1;
	}

	34% {
		opacity: 0;
	}

	51% {
		opacity: 0;
	}

	68% {
		opacity: 0;
	}

	85% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}

}

@keyframes logo-dota {

	0% {
		opacity: 0;
	}

	17% {
		opacity: 0;
	}

	34% {
		opacity: 1;
	}

	51% {
		opacity: 0;
	}

	68% {
		opacity: 0;
	}

	85% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}

}

@keyframes logo-csgo {

	0% {
		opacity: 0;
	}

	17% {
		opacity: 0;
	}

	34% {
		opacity: 0;
	}

	51% {
		opacity: 1;
	}

	68% {
		opacity: 0;
	}

	85% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}

}

@keyframes logo-ow {

	0% {
		opacity: 0;
	}

	17% {
		opacity: 0;
	}

	34% {
		opacity: 0;
	}

	51% {
		opacity: 0;
	}

	68% {
		opacity: 1;
	}

	85% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}

}
@keyframes logo-valorant {

	0% {
		opacity: 0;
	}

	17% {
		opacity: 0;
	}

	34% {
		opacity: 0;
	}

	51% {
		opacity: 0;
	}

	68% {
		opacity: 0;
	}

	85% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}

}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
