.dropdown, .dropup {
	width: fit-content;
	.dropdown-menu {
		@include transition();
		width: 100%;
		min-width: fit-content;
		background: rgba(0,0,0, 0.75)!important;
		border: 2px solid $default!important;
		border-radius: 6px;
		padding: 0!important;
		font-size: 14px!important;
		@include montserrat-regular();
		@include colourise-from-parent(border-color, default, !important);
		.dropdown-item {
			@include transition();
			padding: 3px 6px;
			color: $white!important;
			text-transform: uppercase;
			&:hover, &.active {
				background: $default-60!important;
				@include colourise-from-parent(background, 60, !important);
			}
		}
	}
}
.dropdown {
	.dropdown-menu {
		border-top-left-radius: 0px!important;
		border-top-right-radius: 0px!important;
		margin-top: -2px;
	}
	&.show button {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	button {
		cursor: pointer;
		@include transition();
		width: 100%;
		background: transparent!important;
		border: 2px solid $default!important;
		color: $white!important;
		box-shadow: none!important;
		@include montserrat-bold();
		font-size: 14px!important;
		display: flex;
		padding-left: 8px;
		justify-content: space-between;
		align-items: center;
		text-transform: uppercase;
		@include colourise-from-parent(border-color, default, !important);
		&:hover {
			@include colourise-from-parent(background, 30, !important);
		}
	}
	.dropdown-filler {
		visibility: hidden;
		pointer-events: none;
		height: 0;
	}
}
.dropdown-toggle::after {
	margin-left: 6px;
    margin-right: -6px;
}
.trigger button {
	@include transition();
	width: fit-content;
	background: transparent!important;
	border: 2px solid $default!important;
	color: $white!important;
	box-shadow: none!important;
	@include montserrat-bold();
	font-size: 14px!important;
	border-radius: 6px;
	text-transform: uppercase;
	cursor: pointer;
	&:hover {
		@include colourise-from-parent(background, 30, !important);
	}
	@include colourise-from-parent(border-color, default, !important);
}
