.settings {
	.loading-wrapper {
		margin-top: 24px;
		margin-bottom: 48px;
	}
}
.settings-tabs {
	margin-top: 48px;
	span > span {
		font-size: 18px;
		border-bottom: 2px solid transparent;
		padding-bottom: 12px;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		&.active, &:hover {
			border-color: $default;
			font-weight: bold;
		}
	}
}
.settings-section {
	.form {
		h2 {
			text-align: left;
			margin: 60px 0 2px;
		}
		.form-check {
			margin: 24px 0 0;
		}
		.form-group {
			margin: 36px 60px 0 0;
			.form-control + label {
				font-size: 14px;
			}
		}
		.form-check.result {
			margin: 60px 0 -48px;
			font-size: 18px;
			color: $default;
			&.error {
				color: $eventvods-error;
			}
		}
		.form-check-label {
			font-size: 14px;
			font-weight: bold;
			padding-left: 36px;
			line-height: 24px;
			user-select: none;
		}
		.form-check-input:only-child {
			position: absolute;
			margin-left: -36px;
			margin-top: 0;
			visibility: hidden;
			pointer-events: none;
		}
		.form-check-input[type=radio]:only-child {
			&:before {
				content: '';
				display: inline-block;
				border-radius: 50%;
				width: 24px;
				height: 24px;
				background: transparent;
				border: 2px solid $white;
				visibility: visible;
			}
			&:checked:before {
				background: radial-gradient(ellipse at center, $default 0%, $default 40%, transparent 41%, transparent 100%);
			}
		}
		.form-check-input[type=checkbox]:only-child {
			&:before {
				content: '';
				display: inline-block;
				border-radius: 6px;
				width: 24px;
				height: 24px;
				background: transparent;
				border: 2px solid $white;
				visibility: visible;
			}
			&:checked:before {
				background: $white;
			}
			&:checked:after {
				content: '';
				display: block;
				position: absolute;
				left: 8px;
				top: 3px;
				width: 8px;
				height: 16px;
				border: solid rgb(0, 0, 0);
				border-width: 0 4px 4px 0;
				transform: rotate(45deg);
				visibility: visible;
			}
		}
		button {
			margin: 60px 0;
		}
	}
}
.form-check.social-login {
	display: flex;
	margin: 24px -15px;
	a.btn {
		margin: 0 24px 0 0;
	}
}
.settings-desc {
	font-size: 18px;
	font-weight: 300;
	line-height: 24px;
}
.settings-desc, .form-check {
	margin-right: 60px;
	display: block;
}
.editable-click img, .editable-controls img {
	opacity: 0.6;
	&:hover {
		opacity: 1;
	}
}
h1.editable-click {
	img {
		vertical-align: 2px;
	}
	color: $white!important;
	line-height: 34px;
	border-bottom: 2px dashed transparent!important;
	width: fit-content;
	padding: 0;
	margin-right: 48px;
	&:hover {
		border-color: $default!important;
		cursor: pointer;
		img {
			opacity: 1;
		}
	}
}
.editable-input {
	background: transparent!important;
	color: $white!important;
	border: 0!important;
	border-bottom: 2px solid $default!important;
	@include montserrat-light();
	@include transition();
	max-width: 250px;
	width: fit-content;
	&:focus {
		outline: none!important;
	}
}
h1.editable-click + form > .editable-controls {
	.editable-input {
		font-size: 30px;
		height: 36px;
	}
	.editable-buttons {
		vertical-align: 6px;
		button {
			margin-left: 0;
			margin-right: 6px;
			background: transparent;
			border: 0;
			&:hover {
				cursor: pointer;
			}
		}
	}
	.editable-error {
		padding-top: 6px;
		position: absolute;
		color: $eventvods-error;
	}
}
