.elements-header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 1rem 0;
}

.elements-actions {
	display: flex;
}
.element-controls {
	margin-top: 2rem;
}
.element-like-counter {
    position: absolute;
    display: flex;
    align-items: center;
    top: .5rem;
    left: .5rem;
    @include montserrat-light();
	z-index: 1;
}
.element-container {
	margin: 0 0 24px 0;
	min-width: 225px;
    .element-border {
        padding: 1rem 0 0;
        border-radius: 6px;
		position: relative;
		overflow: hidden;
		display: block;
		text-decoration: none!important;
		&:hover h4 {
			text-decoration: underline!important;
		}
		.element-border__icon {
			position: absolute;
			bottom: 30px;
    		right: -20px;
			img {
				opacity: 0.2;
			}
		}
        @include colourise-from-self(background, 60, !important);
	}
	.element-hero {
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
        align-items: center;
		justify-content: space-around;
		height: 11rem;
		padding: 0.5rem 0;
		> .element-logo, .element-title {
            display: flex;
            justify-content: center;
			align-items: center;
			flex-direction: column;
            width: 100%;
            height: 60%;
            >img {
                vertical-align: middle;
                pointer-events: none;
                max-height: 100%;
                max-width: 50%;
            }
        }
        .element-tag {
            color: white;
            font-size: 2em;
            height: 45px;
            display: block;
            padding: 0;
        }
        .element-teams {
            width: 100%;
            color: white;
            height: 150px;
            padding-right: 10%;
            padding-left: 20px;
            .element-vs {
                font-size: large;
                height: 150px;
                position: relative;
                top: -45px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .element-team {
                height: 150px;
                font-weight: 300;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 2em;
                >img {
                    max-height: 60%;
                    margin: 5px auto 5px auto;
                    pointer-events: none;
                }
                .spoiler-text {
                    font-size: 70%;
                    text-transform: uppercase;
                    white-space: nowrap;
                }
                &.team-1 {
                    margin-right: -20px;
                }
                &.team-2 {
                    margin-left: -20px;
                }
            }
        }
	}
	.element-background {
		position: absolute;
        width: 100%;
		height: 11rem;
		z-index: -1;
		opacity: 0.75;
		filter: saturate(.75) brightness(.75);
		@for $index from 1 to $element-images + 1 {
			&.element-#{$index} {
				background: url('/assets/images/elements/#{$index}.jpg');
			}
		}
	}
	.element-fade {
		position: absolute;
		width: 100%;
		height: 11rem;
		z-index: -1;
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 48%,rgba(0,0,0,0.25) 94%,rgba(0,0,0,0.25) 100%);
	}

}
.element-progress-days {
	width: 100%;
	height: 5px;
	display: block;
	@include transition();
	@include colourise-from-parent(background, 30, !important);
	overflow: hidden;
	div {
		width: 0px;
		height: 5px;
		margin: 0;
		padding: 0;
		float: left;
		background: $white-60;
		animation-name: loadBar;
		animation-duration: 0.5s;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
		animation-timing-function: ease-out;
	}
}
.element-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: .5rem .8rem;
    font-size: .9rem;
    p {
        @include montserrat-bold();
        margin: 2px 0;
    }
    span {
        @include montserrat-light();
    }
}

.element-title {
    width: 90%;
	text-align: center;
	padding: 0 12px;
	text-shadow: 0 0 1px $black;
    h4 {
        @include montserrat-bold();
        font-size: 1rem;
        margin: 5px 0;
    }
    span {
        @include montserrat-regular();
    }
}

.element-info {
    .element-buttons {
        margin-bottom: 10px;
    }
    .element-buttons a {
        margin: 0 25px;
        width: 240px;
        &:first-child {
            background: $lol-dark!important;
        }
    }
    .element-dates {
        display: block;
        @include montserrat-light();
    }
}

.element-links {
    flex-basis: 0;
    flex-grow: 2;
    height: 150px;
    .element-titles {
        width: 100%;
        margin-bottom: 5px;
        h6 {
            font-size: 1.3em;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 25%;
                bottom: 0px;
                height: 1px;
                width: 50%;
                /* or 100px */
                border-bottom: 3px solid $default;
                transform: translateY(5px);
            }
        }
    }
    .element-go {
        width: 100%;
        margin-bottom: 0;
        .row {
            margin-bottom: 0;
            margin-right: 0;
            a {
                opacity: 0.6;
                &:hover {
                    opacity: 1;
                }
                .responsive-img {
                    padding: 0 25px;
                    pointer-events: none;
                    max-height: 60px;
                }
            }
        }
    }
    .element-extras {
        position: relative;
        top: -10px;
        margin-top: 5px;
        a.btn {
            background: transparent;
            border: 1px solid $csgo-dark;
            color: $lol-dark!important;
            margin: 5px 10px;
        }
    }
}

.element-favourite-back {
    width: 0px;
    height: 0px;
    position: absolute;
    top: -50px;
    right: -50px;
    border: 50px solid transparent;
    border-left-color: $lol-dark;
    transform: rotate(-45deg);
}

.element-favourite {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    >i.material-icons {
        font-size: 2em;
    }
}

.dark .element-container {
    .element-border {
        background: white;
    }
    .element-extras a.btn {
        color: white!important;
    }
}

.element-status {
    margin: .3rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.element-icon {
    padding-right: .5rem;
}
