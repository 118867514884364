/* Color palette */
/* Event progress bar */
@keyframes loadBar {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes logo-ev {
  0% {
    opacity: 1; }
  17% {
    opacity: 0; }
  34% {
    opacity: 0; }
  51% {
    opacity: 0; }
  68% {
    opacity: 0; }
  85% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes logo-lol {
  0% {
    opacity: 0; }
  17% {
    opacity: 1; }
  34% {
    opacity: 0; }
  51% {
    opacity: 0; }
  68% {
    opacity: 0; }
  85% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes logo-dota {
  0% {
    opacity: 0; }
  17% {
    opacity: 0; }
  34% {
    opacity: 1; }
  51% {
    opacity: 0; }
  68% {
    opacity: 0; }
  85% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes logo-csgo {
  0% {
    opacity: 0; }
  17% {
    opacity: 0; }
  34% {
    opacity: 0; }
  51% {
    opacity: 1; }
  68% {
    opacity: 0; }
  85% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes logo-ow {
  0% {
    opacity: 0; }
  17% {
    opacity: 0; }
  34% {
    opacity: 0; }
  51% {
    opacity: 0; }
  68% {
    opacity: 1; }
  85% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes logo-valorant {
  0% {
    opacity: 0; }
  17% {
    opacity: 0; }
  34% {
    opacity: 0; }
  51% {
    opacity: 0; }
  68% {
    opacity: 0; }
  85% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.hero {
  width: 60%;
  text-align: center;
  margin: 0 auto;
  min-width: 260px;
  transition: all 0.3s linear; }

.hero-title {
  margin-bottom: 3rem;
  margin-top: 12px;
  font-size: 30px;
  min-width: 260px;
  transition: all 0.3s linear;
  font-family: Montserrat !important;
  font-weight: 300 !important;
  letter-spacing: .25px; }

.empty-notice {
  margin: 36px auto; }

.load-more {
  margin: 12px auto; }

.row.hero-icons {
  margin-bottom: 2rem; }
  .row.hero-icons > div {
    display: flex;
    justify-content: center;
    align-items: center; }

.hero-icon {
  width: 120px;
  height: 120px;
  display: flex;
  pointer-events: none;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s linear; }
  .hero-icon * {
    pointer-events: initial; }
  .hero-icon:hover .hero-icon__bg {
    border: 2px solid white; }
  .hero-icon:hover .hero-icon__icon, .hero-icon:hover .hero-icon__title {
    opacity: 1; }
  .hero-icon.active .hero-icon__bg {
    border: 2px solid transparent; }
    .lol .hero-icon.active .hero-icon__bg {
      background: rgba(0, 180, 177, 0.6); }
    .dota .hero-icon.active .hero-icon__bg {
      background: rgba(223, 39, 0, 0.6); }
    .csgo .hero-icon.active .hero-icon__bg {
      background: rgba(51, 163, 212, 0.6); }
    .overwatch .hero-icon.active .hero-icon__bg {
      background: rgba(255, 137, 0, 0.6); }
    .rocket-league .hero-icon.active .hero-icon__bg {
      background: rgba(160, 117, 228, 0.6); }
    .valorant .hero-icon.active .hero-icon__bg {
      background: rgba(255, 70, 85, 0.6); }
  .hero-icon.active .hero-icon__icon, .hero-icon.active .hero-icon__title {
    opacity: 1; }

.hero-icon__bg {
  transition: all 0.3s linear;
  border-radius: 6px;
  width: 84px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.4); }

.hero-icon__icon {
  padding: .75rem;
  width: 84px;
  opacity: 0.4;
  transition: all 0.3s linear; }

p.hero-icon__title {
  transition: all 0.3s linear;
  opacity: 0.4;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  padding-top: .75rem; }

.event-title h2 {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 18px; }

.search-field {
  width: 100%;
  border-bottom: 2px solid #00db7b !important;
  position: relative;
  transition: all 0.3s linear;
  color: #fff; }
  .lol .search-field {
    border-bottom-color: #00b4b1 !important; }
  .dota .search-field {
    border-bottom-color: #df2700 !important; }
  .csgo .search-field {
    border-bottom-color: #33a3d4 !important; }
  .overwatch .search-field {
    border-bottom-color: #FF8900 !important; }
  .rocket-league .search-field {
    border-bottom-color: #a075e4 !important; }
  .valorant .search-field {
    border-bottom-color: #FF4655 !important; }
  .search-field input {
    top: 4px; }
  .search-field img {
    position: absolute;
    top: .8rem;
    right: 0rem; }
    .search-field img[ng-click] {
      top: 1rem;
      transform: scale(1.5); }

.events-row {
  margin-top: 36px; }

.event-controls .btn {
  padding: 6px 12px; }

.event-controls .d-flex.justify-content-md-start div:first-child {
  margin-right: 24px; }

.event-controls .d-flex.justify-content-md-end div:last-child {
  margin-left: 24px; }

.full_stream_icon {
  display: inline-block;
  opacity: .6;
  text-decoration: none !important;
  margin-left: 12px;
  position: relative;
  top: -1px; }
  .full_stream_icon img {
    pointer-events: none;
    height: 22px; }
  .full_stream_icon:hover {
    opacity: 1; }

.dropdown, .dropup {
  width: fit-content; }
  .dropdown .dropdown-menu, .dropup .dropdown-menu {
    transition: all 0.3s linear;
    width: 100%;
    min-width: fit-content;
    background: rgba(0, 0, 0, 0.75) !important;
    border: 2px solid #00db7b !important;
    border-radius: 6px;
    padding: 0 !important;
    font-size: 14px !important;
    font-family: Montserrat !important;
    font-weight: 400 !important; }
    .lol .dropdown .dropdown-menu, .lol .dropup .dropdown-menu {
      border-color: #00b4b1 !important; }
    .dota .dropdown .dropdown-menu, .dota .dropup .dropdown-menu {
      border-color: #df2700 !important; }
    .csgo .dropdown .dropdown-menu, .csgo .dropup .dropdown-menu {
      border-color: #33a3d4 !important; }
    .overwatch .dropdown .dropdown-menu, .overwatch .dropup .dropdown-menu {
      border-color: #FF8900 !important; }
    .rocket-league .dropdown .dropdown-menu, .rocket-league .dropup .dropdown-menu {
      border-color: #a075e4 !important; }
    .valorant .dropdown .dropdown-menu, .valorant .dropup .dropdown-menu {
      border-color: #FF4655 !important; }
    .dropdown .dropdown-menu .dropdown-item, .dropup .dropdown-menu .dropdown-item {
      transition: all 0.3s linear;
      padding: 3px 6px;
      color: #ffffff !important;
      text-transform: uppercase; }
      .dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item.active, .dropup .dropdown-menu .dropdown-item:hover, .dropup .dropdown-menu .dropdown-item.active {
        background: rgba(0, 219, 123, 0.6) !important; }
        .lol .dropdown .dropdown-menu .dropdown-item:hover, .lol .dropdown .dropdown-menu .dropdown-item.active, .lol .dropup .dropdown-menu .dropdown-item:hover, .lol .dropup .dropdown-menu .dropdown-item.active {
          background: rgba(0, 180, 177, 0.6) !important; }
        .dota .dropdown .dropdown-menu .dropdown-item:hover, .dota .dropdown .dropdown-menu .dropdown-item.active, .dota .dropup .dropdown-menu .dropdown-item:hover, .dota .dropup .dropdown-menu .dropdown-item.active {
          background: rgba(223, 39, 0, 0.6) !important; }
        .csgo .dropdown .dropdown-menu .dropdown-item:hover, .csgo .dropdown .dropdown-menu .dropdown-item.active, .csgo .dropup .dropdown-menu .dropdown-item:hover, .csgo .dropup .dropdown-menu .dropdown-item.active {
          background: rgba(51, 163, 212, 0.6) !important; }
        .overwatch .dropdown .dropdown-menu .dropdown-item:hover, .overwatch .dropdown .dropdown-menu .dropdown-item.active, .overwatch .dropup .dropdown-menu .dropdown-item:hover, .overwatch .dropup .dropdown-menu .dropdown-item.active {
          background: rgba(255, 137, 0, 0.6) !important; }
        .rocket-league .dropdown .dropdown-menu .dropdown-item:hover, .rocket-league .dropdown .dropdown-menu .dropdown-item.active, .rocket-league .dropup .dropdown-menu .dropdown-item:hover, .rocket-league .dropup .dropdown-menu .dropdown-item.active {
          background: rgba(160, 117, 228, 0.6) !important; }
        .valorant .dropdown .dropdown-menu .dropdown-item:hover, .valorant .dropdown .dropdown-menu .dropdown-item.active, .valorant .dropup .dropdown-menu .dropdown-item:hover, .valorant .dropup .dropdown-menu .dropdown-item.active {
          background: rgba(255, 70, 85, 0.6) !important; }

.dropdown .dropdown-menu {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  margin-top: -2px; }

.dropdown.show button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.dropdown button {
  cursor: pointer;
  transition: all 0.3s linear;
  width: 100%;
  background: transparent !important;
  border: 2px solid #00db7b !important;
  color: #ffffff !important;
  box-shadow: none !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  display: flex;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase; }
  .lol .dropdown button {
    border-color: #00b4b1 !important; }
  .dota .dropdown button {
    border-color: #df2700 !important; }
  .csgo .dropdown button {
    border-color: #33a3d4 !important; }
  .overwatch .dropdown button {
    border-color: #FF8900 !important; }
  .rocket-league .dropdown button {
    border-color: #a075e4 !important; }
  .valorant .dropdown button {
    border-color: #FF4655 !important; }
  .lol .dropdown button:hover {
    background: rgba(0, 180, 177, 0.3) !important; }
  .dota .dropdown button:hover {
    background: rgba(223, 39, 0, 0.3) !important; }
  .csgo .dropdown button:hover {
    background: rgba(51, 163, 212, 0.3) !important; }
  .overwatch .dropdown button:hover {
    background: rgba(255, 137, 0, 0.3) !important; }
  .rocket-league .dropdown button:hover {
    background: rgba(160, 117, 228, 0.3) !important; }
  .valorant .dropdown button:hover {
    background: rgba(255, 70, 85, 0.3) !important; }

.dropdown .dropdown-filler {
  visibility: hidden;
  pointer-events: none;
  height: 0; }

.dropdown-toggle::after {
  margin-left: 6px;
  margin-right: -6px; }

.trigger button {
  transition: all 0.3s linear;
  width: fit-content;
  background: transparent !important;
  border: 2px solid #00db7b !important;
  color: #ffffff !important;
  box-shadow: none !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  border-radius: 6px;
  text-transform: uppercase;
  cursor: pointer; }
  .lol .trigger button:hover {
    background: rgba(0, 180, 177, 0.3) !important; }
  .dota .trigger button:hover {
    background: rgba(223, 39, 0, 0.3) !important; }
  .csgo .trigger button:hover {
    background: rgba(51, 163, 212, 0.3) !important; }
  .overwatch .trigger button:hover {
    background: rgba(255, 137, 0, 0.3) !important; }
  .rocket-league .trigger button:hover {
    background: rgba(160, 117, 228, 0.3) !important; }
  .valorant .trigger button:hover {
    background: rgba(255, 70, 85, 0.3) !important; }
  .lol .trigger button {
    border-color: #00b4b1 !important; }
  .dota .trigger button {
    border-color: #df2700 !important; }
  .csgo .trigger button {
    border-color: #33a3d4 !important; }
  .overwatch .trigger button {
    border-color: #FF8900 !important; }
  .rocket-league .trigger button {
    border-color: #a075e4 !important; }
  .valorant .trigger button {
    border-color: #FF4655 !important; }

.vm-placement:not([data-pos]) {
  display: flex;
  margin: auto;
  border: 1px solid #00db7b;
  background: #192129;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #00db7b; }
  .lol .vm-placement:not([data-pos]) {
    border-color: #00b4b1; }
  .dota .vm-placement:not([data-pos]) {
    border-color: #df2700; }
  .csgo .vm-placement:not([data-pos]) {
    border-color: #33a3d4; }
  .overwatch .vm-placement:not([data-pos]) {
    border-color: #FF8900; }
  .rocket-league .vm-placement:not([data-pos]) {
    border-color: #a075e4; }
  .valorant .vm-placement:not([data-pos]) {
    border-color: #FF4655; }
  .lol .vm-placement:not([data-pos]) {
    background: #123235; }
  .dota .vm-placement:not([data-pos]) {
    background: #2d0e0d; }
  .csgo .vm-placement:not([data-pos]) {
    background: #112041; }
  .overwatch .vm-placement:not([data-pos]) {
    background: #2c150a; }
  .rocket-league .vm-placement:not([data-pos]) {
    background: #002416; }
  .valorant .vm-placement:not([data-pos]) {
    background: #0F1923; }
  .vm-placement:not([data-pos]) .patreon-c2a {
    display: inline-block;
    margin-top: 6px; }
    .vm-placement:not([data-pos]) .patreon-c2a img {
      max-height: 32px;
      pointer-events: none; }
  .vm-placement:not([data-pos])[data-id="5b11556b46e0fb000159d592"], .vm-placement:not([data-pos])[data-id="5b11557d46e0fb00013524dd"] {
    width: 100%;
    max-width: 728px;
    min-height: 90px;
    padding: 12px; }

.vm-placement[data-pos] div {
  display: none; }

html, body, .contents {
  height: 100%; }

body {
  background: #1d1d1d;
  color: #ffffff;
  font-size: 14px;
  text-align: left;
  font-family: Montserrat !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3); }

h1,
.h1 {
  font-family: Montserrat;
  font-size: 30px;
  text-align: center;
  color: #ffffff; }

h2,
.h2 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: #ffffff; }

h3,
.h3 {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff; }

h4,
.h4 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff; }

h5,
.h5 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #ffffff; }

h1 > img, h2 > img, h3 > img, h4 > img, h5 > img,
.h1 > img, .h2 > img, .h3 > img, .h4 > img, .h5 > img {
  vertical-align: 1px; }

.light, .header-title .light {
  font-weight: 300 !important; }

a.large {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #ffffff; }

p.large {
  font-size: 18px; }

p.message {
  font-size: 18px;
  margin: 36px auto;
  max-width: 800px; }
  p.message a {
    color: #00db7b; }
    .lol p.message a {
      color: #00b4b1; }
    .dota p.message a {
      color: #df2700; }
    .csgo p.message a {
      color: #33a3d4; }
    .overwatch p.message a {
      color: #FF8900; }
    .rocket-league p.message a {
      color: #a075e4; }
    .valorant p.message a {
      color: #FF4655; }

[ng-click],
[data-toggle="tooltip"],
[data-toggle="modal"],
[du-smooth-scroll],
button[type=submit],
.btn-login {
  cursor: pointer; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  text-decoration: initial; }

a[ng-click]:hover {
  text-decoration: underline !important; }

[ng-cloak],
img:not([src]),
.hide {
  display: none !important; }

.invisible {
  visibility: hidden !important; }

a:not(.btn) {
  color: #ffffff; }

.heart {
  margin-right: 5px;
  position: relative;
  top: -1px; }
  .heart:hover {
    transform: scale(1.1); }

.force-uppercase {
  text-transform: uppercase !important; }

.force-lowercase {
  text-transform: lowercase !important; }

.force-normal {
  text-transform: none !important; }

.navigation-logo {
  display: block;
  position: relative;
  width: 214px;
  height: 52px; }

.logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 214px;
  height: 52px;
  object-fit: contain;
  pointer-events: none;
  transition: all 0.3s linear;
  opacity: 1; }
  .logo.ng-hide {
    opacity: 0; }

.btn-auth {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  transition: all 0.3s linear;
  border: 2px solid #00db7b;
  padding: 0 14px;
  height: 30px;
  line-height: 26px;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
  background: transparent; }
  .lol .btn-auth {
    border-color: #00b4b1; }
  .dota .btn-auth {
    border-color: #df2700; }
  .csgo .btn-auth {
    border-color: #33a3d4; }
  .overwatch .btn-auth {
    border-color: #FF8900; }
  .rocket-league .btn-auth {
    border-color: #a075e4; }
  .valorant .btn-auth {
    border-color: #FF4655; }
  .btn-auth:hover {
    color: #ffffff;
    text-decoration: underline !important; }
  .btn-auth:last-child {
    margin-left: 24px; }
  .btn-auth.filled {
    color: #1d1d1d !important;
    background: #00db7b; }
    .lol .btn-auth.filled {
      background: #00b4b1; }
    .dota .btn-auth.filled {
      background: #df2700; }
    .csgo .btn-auth.filled {
      background: #33a3d4; }
    .overwatch .btn-auth.filled {
      background: #FF8900; }
    .rocket-league .btn-auth.filled {
      background: #a075e4; }
    .valorant .btn-auth.filled {
      background: #FF4655; }
    .lol .btn-auth.filled {
      border-color: #00b4b1; }
    .dota .btn-auth.filled {
      border-color: #df2700; }
    .csgo .btn-auth.filled {
      border-color: #33a3d4; }
    .overwatch .btn-auth.filled {
      border-color: #FF8900; }
    .rocket-league .btn-auth.filled {
      border-color: #a075e4; }
    .valorant .btn-auth.filled {
      border-color: #FF4655; }

.contents {
  background: #192129;
  transition: background 0.5s ease-in-out;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ffffff; }
  .lol .contents {
    background: #123235; }
  .dota .contents {
    background: #2d0e0d; }
  .csgo .contents {
    background: #112041; }
  .overwatch .contents {
    background: #2c150a; }
  .rocket-league .contents {
    background: #002416; }
  .valorant .contents {
    background: #0F1923; }
  .contents .background {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    transition: opacity 0.5s ease-in-out;
    opacity: 1; }
    .contents .background.ng-hide {
      opacity: 0; }
  .contents #main, .contents .flex-filler {
    flex-grow: 1; }
  .contents .container {
    font-family: Montserrat !important;
    font-weight: 400 !important;
    padding: 24px 0; }
    .contents .container.header {
      padding: 36px 0 24px; }

.loading-wrapper {
  vertical-align: middle; }

.loader {
  display: inline-block;
  width: 100%;
  max-height: 100px;
  min-height: 50px;
  text-align: center; }
  .loader .loader-ev {
    animation-iteration-count: infinite;
    animation-duration: 2500ms;
    animation-timing-function: cubic-bezier(0.685, 0.08, 0.245, 0.855);
    height: 50px;
    transform: translate3d(0, 0, 0);
    animation-name: logo-ev; }
  .loader .loader-lol {
    animation-iteration-count: infinite;
    animation-duration: 2500ms;
    animation-timing-function: cubic-bezier(0.685, 0.08, 0.245, 0.855);
    height: 50px;
    transform: translate3d(0, 0, 0);
    animation-name: logo-lol; }
  .loader .loader-dota {
    animation-iteration-count: infinite;
    animation-duration: 2500ms;
    animation-timing-function: cubic-bezier(0.685, 0.08, 0.245, 0.855);
    height: 50px;
    transform: translate3d(0, 0, 0);
    animation-name: logo-dota; }
  .loader .loader-csgo {
    animation-iteration-count: infinite;
    animation-duration: 2500ms;
    animation-timing-function: cubic-bezier(0.685, 0.08, 0.245, 0.855);
    height: 50px;
    transform: translate3d(0, 0, 0);
    animation-name: logo-csgo; }
  .loader .loader-ow {
    animation-iteration-count: infinite;
    animation-duration: 2500ms;
    animation-timing-function: cubic-bezier(0.685, 0.08, 0.245, 0.855);
    height: 50px;
    transform: translate3d(0, 0, 0);
    animation-name: logo-ow; }
  .loader .loader-valorant {
    animation-iteration-count: infinite;
    animation-duration: 2500ms;
    animation-timing-function: cubic-bezier(0.685, 0.08, 0.245, 0.855);
    height: 50px;
    transform: translate3d(0, 0, 0);
    animation-name: logo-valorant; }

.btn.fav-btn {
  position: relative;
  padding-left: 42px; }
  .btn.fav-btn .icon {
    position: absolute;
    left: 0;
    top: 0;
    background: #00db7b;
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .lol .btn.fav-btn .icon {
      background: #00b4b1; }
    .dota .btn.fav-btn .icon {
      background: #df2700; }
    .csgo .btn.fav-btn .icon {
      background: #33a3d4; }
    .overwatch .btn.fav-btn .icon {
      background: #FF8900; }
    .rocket-league .btn.fav-btn .icon {
      background: #a075e4; }
    .valorant .btn.fav-btn .icon {
      background: #FF4655; }

.cookies-disclaimer, .notification-bar {
  transition: all 0.3s linear;
  transform: translateY(0);
  opacity: 1;
  width: 100%;
  z-index: 2;
  background: #00db7b;
  border-bottom: 0;
  color: #1d1d1d;
  font-size: 14px;
  padding: 0;
  border: 0; }
  .cookies-disclaimer a, .notification-bar a {
    font-family: Montserrat !important;
    font-weight: 700 !important;
    color: #1d1d1d; }
    .cookies-disclaimer a:hover, .notification-bar a:hover {
      text-decoration: underline; }
  .lol .cookies-disclaimer, .lol .notification-bar {
    background: #00b4b1; }
  .dota .cookies-disclaimer, .dota .notification-bar {
    background: #df2700; }
  .csgo .cookies-disclaimer, .csgo .notification-bar {
    background: #33a3d4; }
  .overwatch .cookies-disclaimer, .overwatch .notification-bar {
    background: #FF8900; }
  .rocket-league .cookies-disclaimer, .rocket-league .notification-bar {
    background: #a075e4; }
  .valorant .cookies-disclaimer, .valorant .notification-bar {
    background: #FF4655; }
  .cookies-disclaimer.lol, .notification-bar.lol {
    background: #00b4b1; }
  .cookies-disclaimer.dota, .notification-bar.dota {
    background: #df2700; }
  .cookies-disclaimer.csgo, .notification-bar.csgo {
    background: #33a3d4; }
  .cookies-disclaimer.overwatch, .notification-bar.overwatch {
    background: #FF8900; }
  .cookies-disclaimer.rocket-league, .notification-bar.rocket-league {
    background: #a075e4; }
  .cookies-disclaimer.valorant, .notification-bar.valorant {
    background: #FF4655; }
  .cookies-disclaimer .container, .notification-bar .container {
    padding: 6px 24px !important;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .cookies-disclaimer a.btn-success, .notification-bar a.btn-success {
    background: #1d1d1d;
    border: 2px #1d1d1d;
    margin-left: 12px;
    font-size: 14px !important;
    color: #ffffff !important;
    border-radius: 6px;
    text-transform: uppercase; }
    .cookies-disclaimer a.btn-success:hover, .notification-bar a.btn-success:hover {
      text-decoration: underline !important; }

.static-header {
  margin: 36px auto; }

.text-columns {
  column-count: 2;
  column-gap: 60px; }

.img-fluid.svg-50 {
  width: 50%; }

.static-intro {
  margin-bottom: 36px;
  line-height: 2; }

.team-member img {
  margin: 24px auto; }

.team-member h2 {
  text-align: left;
  letter-spacing: 1px;
  margin-bottom: 6px; }

.team-member p {
  margin-top: 12px; }

.team-member.recruit-member img {
  filter: grayscale(100%); }

.static-last {
  margin-bottom: 48px; }

.promote {
  font-size: 18px; }
  .promote blockquote {
    font-size: 18px; }

.faq .col-12.col-sm-6 {
  margin-bottom: 24px; }

.static-page h1 {
  font-weight: bold;
  margin-bottom: 24px; }

.static-page .card {
  background: transparent; }
  .static-page .card .lead {
    font-size: 18px;
    font-weight: normal; }
  .static-page .card > .card-block {
    border: 2px solid rgba(0, 219, 123, 0.6); }
    .static-page .card > .card-block + .card-block {
      border-top: 0; }
  .static-page .card > .row {
    border: 2px solid rgba(0, 219, 123, 0.6);
    border-top: 0; }
    .static-page .card > .row .img-fluid {
      max-width: 75%; }
    .static-page .card > .row .card-block {
      padding: 24px;
      text-align: center; }
      .static-page .card > .row .card-block:first-child {
        padding: 0; }
    .static-page .card > .row.author .img-fluid {
      max-width: 85%;
      max-height: 100px;
      margin: 12px auto; }
    .static-page .card > .row.author .card-title {
      margin: 12px auto auto 0; }
    .static-page .card > .row.author .card-block {
      padding: 0; }
    .static-page .card > .row.author .card-text {
      margin: 12px 24px 24px 0;
      text-align: left; }
    .static-page .card > .row + .card-block {
      border-top: 0; }

.static-page .card-header, .static-page .card-footer {
  background: rgba(0, 219, 123, 0.6);
  text-align: left; }

.static-page h2.card-title {
  text-align: left; }

.static-page h3.card-title {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 24px; }

.static-page h4.card-title {
  font-family: Montserrat !important;
  font-weight: 400 !important;
  font-size: 18px; }

.static-page .card-block {
  background: #192129;
  padding: 48px; }
  .static-page .card-block .btn {
    transition: all 0.3s linear;
    background: rgba(0, 219, 123, 0.6);
    border: 2px solid transparent !important;
    color: white !important;
    box-shadow: none !important; }
    .static-page .card-block .btn:hover {
      border: 2px solid rgba(0, 219, 123, 0.6) !important;
      text-decoration: underline; }
  .static-page .card-block.team-desc {
    border-bottom: 2px #192129; }

.static-page .card-header {
  border-bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px; }

.static-page .card-footer {
  border-top: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: normal; }

.article p:not(.lead) {
  letter-spacing: .25px; }

nav.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  nav.navigation div {
    width: fit-content;
    text-align: right; }

.alert {
  border-radius: 0 !important; }

.search-field input[type=text] {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  background: 0;
  width: 100%;
  border: 0;
  display: block;
  position: relative;
  outline: 0;
  color: #ffffff;
  padding: .5rem 0; }
  .search-field input[type=text]::placeholder {
    color: #ffffff; }

.sort-field {
  float: right; }

.sort-field select {
  outline: 0;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  width: 100%;
  color: #ffffff;
  border: 2px solid #00db7b;
  background: 0;
  text-transform: uppercase;
  padding: .5rem 0; }

.form-control {
  font-size: 14px; }

.form-group {
  position: relative;
  height: 50px;
  margin-bottom: 12px; }
  .form-group.fit {
    height: fit-content;
    margin-bottom: 24px !important; }
    .form-group.fit span.error {
      margin-top: 7px;
      position: absolute;
      color: #00db7b;
      transition: all 0.3s linear;
      opacity: 0;
      transform: translateY(50%);
      pointer-events: none; }
    .form-group.fit .ng-invalid.ng-touched {
      border-color: #00db7b !important; }
    .form-group.fit .ng-invalid.ng-touched + span.error {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      transform: translateY(0%);
      opacity: 1;
      pointer-events: auto; }
  .form-group label, .form-group input {
    position: absolute;
    left: 0;
    top: 0;
    color: #ffffff !important;
    font-size: 14px; }
  .form-group label {
    display: block;
    width: 100%;
    padding: .5rem .75rem .5rem 0;
    line-height: 1.25;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    font-family: Montserrat !important;
    font-weight: 700 !important; }
  .form-group input {
    font-family: Montserrat !important;
    font-weight: 700 !important;
    background: transparent !important;
    border: 0 !important;
    border-bottom: 2px solid #ffffff !important;
    z-index: 3;
    padding-left: 0;
    color: #ffffff;
    border-radius: 0 !important; }
  .form-group input:focus + label, .form-group input.ng-not-empty + label, .form-group input.ng-valid:not(.ng-empty) + label {
    font-size: 12px;
    transform: translateY(-60%);
    color: rgba(255, 255, 255, 0.4); }
  .form-group div {
    position: absolute;
    top: 42px;
    color: #00db7b !important;
    transition: all 0.3s linear;
    opacity: 0;
    transform: translateY(50%);
    pointer-events: none; }
  .form-group input:not([type="password"]):not(:focus).ng-invalid:not(.ng-empty):not(.ng-invalid-unique-email):not(.ng-invalid-unique-name),
  .form-group input:not([type="password"]):not(:focus).ng-invalid-required.ng-touched {
    border-color: #00db7b !important; }
    .form-group input:not([type="password"]):not(:focus).ng-invalid:not(.ng-empty):not(.ng-invalid-unique-email):not(.ng-invalid-unique-name) + label + div.required,
    .form-group input:not([type="password"]):not(:focus).ng-invalid:not(.ng-empty):not(.ng-invalid-unique-email):not(.ng-invalid-unique-name) + label + div + div.required,
    .form-group input:not([type="password"]):not(:focus).ng-invalid-required.ng-touched + label + div.required,
    .form-group input:not([type="password"]):not(:focus).ng-invalid-required.ng-touched + label + div + div.required {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      transform: translateY(0%);
      opacity: 1;
      pointer-events: auto; }
  .form-group input[type="password"].ng-invalid-pattern:not(.ng-empty) {
    border-color: #00db7b !important; }
    .form-group input[type="password"].ng-invalid-pattern:not(.ng-empty) + label + div.valid {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      transform: translateY(0%);
      opacity: 1;
      pointer-events: auto; }
  .form-group input[type="password"]:not(:focus).ng-invalid-required.ng-touched.ng-valid-pattern {
    border-color: #00db7b !important; }
    .form-group input[type="password"]:not(:focus).ng-invalid-required.ng-touched.ng-valid-pattern + label + div + div.required {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      transform: translateY(0%);
      opacity: 1;
      pointer-events: auto; }
  #login .form-group input[type="password"].ng-invalid-required.ng-touched {
    border-color: #00db7b !important; }
    #login .form-group input[type="password"].ng-invalid-required.ng-touched + label + div.required {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      transform: translateY(0%);
      opacity: 1;
      pointer-events: auto; }
  #login .form-group input[type="password"].invalid-pass {
    border-color: #00db7b !important; }
    #login .form-group input[type="password"].invalid-pass + label + div.required + div.invalid {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      transform: translateY(0%);
      opacity: 1;
      pointer-events: auto; }
  #register .form-group input[type="email"].ng-invalid-unique-email.ng-touched,
  #register .form-group input[type="text"].ng-invalid-unique-name.ng-touched {
    border-color: #00db7b !important; }
    #register .form-group input[type="email"].ng-invalid-unique-email.ng-touched + label + div.conflict,
    #register .form-group input[type="text"].ng-invalid-unique-name.ng-touched + label + div.conflict {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      transform: translateY(0%);
      opacity: 1;
      pointer-events: auto; }

label.ev-checkbox {
  cursor: pointer;
  margin: 4px 0; }
  label.ev-checkbox input {
    opacity: 0;
    pointer-events: none;
    position: absolute; }
  label.ev-checkbox div.flex-center {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    font-size: 18px;
    margin-top: 1px;
    border-radius: 6px;
    background: #ffffff;
    color: #1d1d1d; }
  label.ev-checkbox p {
    margin: initial;
    padding-left: 24px; }

.form div.col-6, .form div.col-12 {
  margin-top: 24px; }

.form div.col-12.row {
  margin-top: 0; }

.form .filler {
  margin: 18px 0; }

.settings .loading-wrapper {
  margin-top: 24px;
  margin-bottom: 48px; }

.settings-tabs {
  margin-top: 48px; }
  .settings-tabs span > span {
    font-size: 18px;
    border-bottom: 2px solid transparent;
    padding-bottom: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px; }
    .settings-tabs span > span.active, .settings-tabs span > span:hover {
      border-color: #00db7b;
      font-weight: bold; }

.settings-section .form h2 {
  text-align: left;
  margin: 60px 0 2px; }

.settings-section .form .form-check {
  margin: 24px 0 0; }

.settings-section .form .form-group {
  margin: 36px 60px 0 0; }
  .settings-section .form .form-group .form-control + label {
    font-size: 14px; }

.settings-section .form .form-check.result {
  margin: 60px 0 -48px;
  font-size: 18px;
  color: #00db7b; }
  .settings-section .form .form-check.result.error {
    color: #00db7b; }

.settings-section .form .form-check-label {
  font-size: 14px;
  font-weight: bold;
  padding-left: 36px;
  line-height: 24px;
  user-select: none; }

.settings-section .form .form-check-input:only-child {
  position: absolute;
  margin-left: -36px;
  margin-top: 0;
  visibility: hidden;
  pointer-events: none; }

.settings-section .form .form-check-input[type=radio]:only-child:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 2px solid #ffffff;
  visibility: visible; }

.settings-section .form .form-check-input[type=radio]:only-child:checked:before {
  background: radial-gradient(ellipse at center, #00db7b 0%, #00db7b 40%, transparent 41%, transparent 100%); }

.settings-section .form .form-check-input[type=checkbox]:only-child:before {
  content: '';
  display: inline-block;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 2px solid #ffffff;
  visibility: visible; }

.settings-section .form .form-check-input[type=checkbox]:only-child:checked:before {
  background: #ffffff; }

.settings-section .form .form-check-input[type=checkbox]:only-child:checked:after {
  content: '';
  display: block;
  position: absolute;
  left: 8px;
  top: 3px;
  width: 8px;
  height: 16px;
  border: solid black;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
  visibility: visible; }

.settings-section .form button {
  margin: 60px 0; }

.form-check.social-login {
  display: flex;
  margin: 24px -15px; }
  .form-check.social-login a.btn {
    margin: 0 24px 0 0; }

.settings-desc {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px; }

.settings-desc, .form-check {
  margin-right: 60px;
  display: block; }

.editable-click img, .editable-controls img {
  opacity: 0.6; }
  .editable-click img:hover, .editable-controls img:hover {
    opacity: 1; }

h1.editable-click {
  color: #ffffff !important;
  line-height: 34px;
  border-bottom: 2px dashed transparent !important;
  width: fit-content;
  padding: 0;
  margin-right: 48px; }
  h1.editable-click img {
    vertical-align: 2px; }
  h1.editable-click:hover {
    border-color: #00db7b !important;
    cursor: pointer; }
    h1.editable-click:hover img {
      opacity: 1; }

.editable-input {
  background: transparent !important;
  color: #ffffff !important;
  border: 0 !important;
  border-bottom: 2px solid #00db7b !important;
  font-family: Montserrat !important;
  font-weight: 300 !important;
  transition: all 0.3s linear;
  max-width: 250px;
  width: fit-content; }
  .editable-input:focus {
    outline: none !important; }

h1.editable-click + form > .editable-controls .editable-input {
  font-size: 30px;
  height: 36px; }

h1.editable-click + form > .editable-controls .editable-buttons {
  vertical-align: 6px; }
  h1.editable-click + form > .editable-controls .editable-buttons button {
    margin-left: 0;
    margin-right: 6px;
    background: transparent;
    border: 0; }
    h1.editable-click + form > .editable-controls .editable-buttons button:hover {
      cursor: pointer; }

h1.editable-click + form > .editable-controls .editable-error {
  padding-top: 6px;
  position: absolute;
  color: #00db7b; }

body, .modal {
  padding: 0 !important; }

.modal.show {
  display: flex !important;
  align-items: center;
  min-height: fit-content; }

.modal-dialog {
  max-width: none;
  width: 100%;
  max-height: 100%;
  margin: 0 auto !important; }

.modal-content {
  height: fit-content;
  border-radius: 6px;
  border: 0;
  padding: 24px;
  max-width: 945px;
  margin: 0 auto;
  background: #192129;
  position: relative;
  transition: background 0.3s linear; }
  .lol .modal-content {
    background: #123235; }
  .dota .modal-content {
    background: #2d0e0d; }
  .csgo .modal-content {
    background: #112041; }
  .overwatch .modal-content {
    background: #2c150a; }
  .rocket-league .modal-content {
    background: #002416; }
  .valorant .modal-content {
    background: #0F1923; }

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  color: rgba(255, 255, 255, 0.4);
  z-index: 999;
  text-shadow: 0; }
  .close:hover {
    color: #ffffff; }

.modal-splash {
  padding: 24px; }
  .modal-splash h4, .modal-splash h5 {
    font-size: 30px;
    text-align: center;
    margin: 0 auto;
    line-height: 1.33; }
  .modal-splash h5 {
    font-weight: 300;
    margin-top: 12px; }

.modal-body {
  padding: 0; }
  .modal-body h5 {
    font-size: 24px; }
  .modal-body h6 {
    font-family: Montserrat !important;
    font-weight: 700 !important;
    font-size: 18px; }

.modal#contact.show select {
  margin-top: 1px;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23FFF' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  appearance: none;
  background-size: 8px 10px;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-weight: bold;
  padding: 0 12px;
  height: calc(2em + 2px); }
  .modal#contact.show select::-ms-expand {
    display: none; }
  .modal#contact.show select option {
    color: #ffffff;
    background: #192129;
    transition: all 0.3s linear; }
    .lol .modal#contact.show select option {
      background: #123235; }
    .dota .modal#contact.show select option {
      background: #2d0e0d; }
    .csgo .modal#contact.show select option {
      background: #112041; }
    .overwatch .modal#contact.show select option {
      background: #2c150a; }
    .rocket-league .modal#contact.show select option {
      background: #002416; }
    .valorant .modal#contact.show select option {
      background: #0F1923; }

.modal#contact.show textarea {
  background: transparent;
  color: #ffffff;
  padding: 12px;
  border-radius: 6px;
  border: 2px solid #ffffff;
  font-weight: bold; }
  .modal#contact.show textarea::placeholder {
    color: #ffffff; }

.modal#contact.show .result {
  font-size: 18px; }

.modal#contact.show form > .row {
  margin-bottom: 24px; }

.modal#contact.show .col-12 > .form-group {
  margin-bottom: 8px; }

.modal#contact.show .col-10 > .form-group {
  margin: 0 15px; }

.modal#event .modal-content {
  transition: all 0.3s linear; }
  .lol .modal#event .modal-content {
    background: #123235 !important; }
  .dota .modal#event .modal-content {
    background: #2d0e0d !important; }
  .csgo .modal#event .modal-content {
    background: #112041 !important; }
  .overwatch .modal#event .modal-content {
    background: #2c150a !important; }
  .rocket-league .modal#event .modal-content {
    background: #002416 !important; }
  .valorant .modal#event .modal-content {
    background: #0F1923 !important; }

.modal#event .details .row:first-child {
  padding-bottom: 0; }

.modal#event .details div {
  margin-top: 24px;
  padding-bottom: 24px; }

.modal#event .details a {
  color: #00db7b;
  transition: all 0.3s linear; }
  .lol .modal#event .details a {
    color: #00b4b1 !important; }
  .dota .modal#event .details a {
    color: #df2700 !important; }
  .csgo .modal#event .details a {
    color: #33a3d4 !important; }
  .overwatch .modal#event .details a {
    color: #FF8900 !important; }
  .rocket-league .modal#event .details a {
    color: #a075e4 !important; }
  .valorant .modal#event .details a {
    color: #FF4655 !important; }

.modal#event .staff, .modal#event .teams {
  padding-bottom: 48px; }
  .modal#event .staff h5, .modal#event .teams h5 {
    margin-bottom: 0; }

.modal#event .staff-container, .modal#event .team-container {
  padding-top: 36px; }

.modal#event .staff a.staff-photo, .modal#event .teams a.team-icon {
  display: block;
  width: 48px;
  text-align: center; }
  .modal#event .staff a.staff-photo img, .modal#event .teams a.team-icon img {
    max-width: 48px;
    pointer-events: none; }

.modal#event a.staff-info, .modal#event a.team-info {
  float: left; }

.modal#event .team-info {
  font-weight: bold; }

.modal#event .event-details__staff-alias {
  font-family: Montserrat !important;
  font-weight: 700 !important; }
  .modal#event .event-details__staff-alias::before {
    content: '"';
    font-family: Montserrat !important;
    font-weight: 300 !important; }
  .modal#event .event-details__staff-alias::after {
    content: '"';
    font-family: Montserrat !important;
    font-weight: 300 !important; }

.static-header.auth {
  margin-bottom: 24px; }

.auth-box {
  border: 1px solid #ffffff;
  padding: 24px 36px;
  height: 100%; }

.auth-features {
  padding-top: 12px; }

.auth-features li {
  line-height: 36px;
  height: 36px;
  margin-top: 12px; }
  .auth-features li i.fa {
    vertical-align: -4px;
    margin-right: 24px;
    color: #00db7b; }

.btn-auth-primary {
  cursor: pointer;
  text-transform: uppercase;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  transition: all 0.3s linear;
  font-size: 14px;
  padding: 8px 24px;
  height: 48px;
  border-radius: 6px;
  width: 100%;
  background: #00db7b;
  border: 2px solid #00db7b;
  color: black; }
  .btn-auth-primary:hover:not([disabled=disabled]) {
    background: transparent !important;
    color: #00db7b; }

.btn-auth-secondary {
  cursor: pointer;
  text-transform: uppercase;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  transition: all 0.3s linear;
  font-size: 14px;
  padding: 8px 24px;
  height: 48px;
  border-radius: 6px;
  width: 100%;
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  margin-top: 48px;
  line-height: 28px; }
  .btn-auth-secondary:hover {
    background: #ffffff !important;
    color: #1d1d1d !important; }

.auth-reset {
  margin: 12px auto;
  float: right;
  font-weight: bold; }

.social-login {
  padding: 46px 0 0; }

.social-login .btn {
  margin: 6px;
  color: #ffffff !important;
  text-transform: uppercase;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 14px;
  padding: 6px;
  border-radius: 6px;
  transition: all 0.3s ease-out; }
  .social-login .btn.btn-facebook {
    background: #3b5998; }
  .social-login .btn.btn-twitter {
    background: #1da1f2; }
  .social-login .btn.btn-google {
    background: #dd4b39; }
  .social-login .btn:hover {
    cursor: pointer;
    text-decoration: none !important;
    box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.3); }
    .social-login .btn:hover div {
      text-decoration: underline !important; }
  .social-login .btn div {
    text-align: left;
    margin-left: 12px; }

footer.page-footer {
  margin-top: 0;
  color: #ffffff;
  background: #1d1d1d;
  padding-top: 12px;
  position: relative; }
  footer.page-footer .container {
    padding: 24px 0; }
  footer.page-footer .col-title, footer.page-footer .col-12 a {
    font-family: Montserrat !important;
    font-weight: 700 !important;
    padding-bottom: 12px; }
  footer.page-footer .col-title {
    font-size: 18px;
    line-height: 24px;
    border: 0;
    border-bottom: 2px solid #00db7b;
    transition: all 0.3s linear; }
    .lol footer.page-footer .col-title {
      border-color: #00b4b1 !important; }
    .dota footer.page-footer .col-title {
      border-color: #df2700 !important; }
    .csgo footer.page-footer .col-title {
      border-color: #33a3d4 !important; }
    .overwatch footer.page-footer .col-title {
      border-color: #FF8900 !important; }
    .rocket-league footer.page-footer .col-title {
      border-color: #a075e4 !important; }
    .valorant footer.page-footer .col-title {
      border-color: #FF4655 !important; }
  footer.page-footer .col-12 a {
    display: block;
    width: 100%; }
    footer.page-footer .col-12 a:first-of-type {
      padding-top: 6px; }
  footer.page-footer .quick-links a, footer.page-footer .social-media a {
    font-size: 14px; }
    footer.page-footer .quick-links a img, footer.page-footer .social-media a img {
      max-height: 1em;
      pointer-events: none;
      position: relative;
      top: -1px; }
  footer.page-footer .social-media a i:last-child {
    font-style: normal; }
  footer.page-footer .social-media a:hover {
    text-decoration: none; }
    footer.page-footer .social-media a:hover i:last-child {
      text-decoration: underline; }
  footer.page-footer .patreon a, footer.page-footer .social-media-mob a {
    padding-top: 6px; }
  footer.page-footer .patreon a {
    opacity: 0.6;
    max-width: 50%; }
    footer.page-footer .patreon a:hover {
      opacity: 1; }
  footer.page-footer .footer-copyright {
    align-items: flex-start;
    letter-spacing: 0.5px;
    font-size: 12px;
    margin-top: 24px;
    padding-top: 12px;
    border-top: 2px solid #00db7b;
    font-family: Montserrat !important;
    font-weight: 400 !important;
    transition: all 0.3s linear; }
    .lol footer.page-footer .footer-copyright {
      border-color: #00b4b1 !important; }
    .dota footer.page-footer .footer-copyright {
      border-color: #df2700 !important; }
    .csgo footer.page-footer .footer-copyright {
      border-color: #33a3d4 !important; }
    .overwatch footer.page-footer .footer-copyright {
      border-color: #FF8900 !important; }
    .rocket-league footer.page-footer .footer-copyright {
      border-color: #a075e4 !important; }
    .valorant footer.page-footer .footer-copyright {
      border-color: #FF4655 !important; }
    footer.page-footer .footer-copyright .col-12 {
      opacity: 0.6;
      padding-right: 12px; }
      footer.page-footer .footer-copyright .col-12.text-right {
        padding: 0;
        opacity: 1;
        font-size: 14px; }

.skip-to-top {
  position: absolute;
  top: 36px;
  right: 84px; }
  .skip-to-top .rel-container {
    position: absolute;
    display: inline-block;
    height: 36px;
    width: 36px;
    opacity: 0.4;
    transform: all 0.5s linear; }
    .skip-to-top .rel-container:hover {
      opacity: 1; }
      .skip-to-top .rel-container:hover img {
        transform: rotate(-90deg); }
    .skip-to-top .rel-container img {
      height: 36px;
      width: 36px;
      pointer-events: none; }

.elements-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0; }

.elements-actions {
  display: flex; }

.element-controls {
  margin-top: 2rem; }

.element-like-counter {
  position: absolute;
  display: flex;
  align-items: center;
  top: .5rem;
  left: .5rem;
  font-family: Montserrat !important;
  font-weight: 300 !important;
  z-index: 1; }

.element-container {
  margin: 0 0 24px 0;
  min-width: 225px; }
  .element-container .element-border {
    padding: 1rem 0 0;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    display: block;
    text-decoration: none !important; }
    .element-container .element-border:hover h4 {
      text-decoration: underline !important; }
    .element-container .element-border .element-border__icon {
      position: absolute;
      bottom: 30px;
      right: -20px; }
      .element-container .element-border .element-border__icon img {
        opacity: 0.2; }
    .element-container .element-border.lol {
      background: rgba(0, 180, 177, 0.6) !important; }
    .element-container .element-border.dota {
      background: rgba(223, 39, 0, 0.6) !important; }
    .element-container .element-border.csgo {
      background: rgba(51, 163, 212, 0.6) !important; }
    .element-container .element-border.overwatch {
      background: rgba(255, 137, 0, 0.6) !important; }
    .element-container .element-border.rocket-league {
      background: rgba(160, 117, 228, 0.6) !important; }
    .element-container .element-border.valorant {
      background: rgba(255, 70, 85, 0.6) !important; }
  .element-container .element-hero {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 11rem;
    padding: 0.5rem 0; }
    .element-container .element-hero > .element-logo, .element-container .element-hero .element-title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 60%; }
      .element-container .element-hero > .element-logo > img, .element-container .element-hero .element-title > img {
        vertical-align: middle;
        pointer-events: none;
        max-height: 100%;
        max-width: 50%; }
    .element-container .element-hero .element-tag {
      color: white;
      font-size: 2em;
      height: 45px;
      display: block;
      padding: 0; }
    .element-container .element-hero .element-teams {
      width: 100%;
      color: white;
      height: 150px;
      padding-right: 10%;
      padding-left: 20px; }
      .element-container .element-hero .element-teams .element-vs {
        font-size: large;
        height: 150px;
        position: relative;
        top: -45px;
        display: flex;
        justify-content: center;
        align-items: center; }
      .element-container .element-hero .element-teams .element-team {
        height: 150px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2em; }
        .element-container .element-hero .element-teams .element-team > img {
          max-height: 60%;
          margin: 5px auto 5px auto;
          pointer-events: none; }
        .element-container .element-hero .element-teams .element-team .spoiler-text {
          font-size: 70%;
          text-transform: uppercase;
          white-space: nowrap; }
        .element-container .element-hero .element-teams .element-team.team-1 {
          margin-right: -20px; }
        .element-container .element-hero .element-teams .element-team.team-2 {
          margin-left: -20px; }
  .element-container .element-background {
    position: absolute;
    width: 100%;
    height: 11rem;
    z-index: -1;
    opacity: 0.75;
    filter: saturate(0.75) brightness(0.75); }
    .element-container .element-background.element-1 {
      background: url("/assets/images/elements/1.jpg"); }
    .element-container .element-background.element-2 {
      background: url("/assets/images/elements/2.jpg"); }
    .element-container .element-background.element-3 {
      background: url("/assets/images/elements/3.jpg"); }
    .element-container .element-background.element-4 {
      background: url("/assets/images/elements/4.jpg"); }
    .element-container .element-background.element-5 {
      background: url("/assets/images/elements/5.jpg"); }
    .element-container .element-background.element-6 {
      background: url("/assets/images/elements/6.jpg"); }
    .element-container .element-background.element-7 {
      background: url("/assets/images/elements/7.jpg"); }
    .element-container .element-background.element-8 {
      background: url("/assets/images/elements/8.jpg"); }
    .element-container .element-background.element-9 {
      background: url("/assets/images/elements/9.jpg"); }
    .element-container .element-background.element-10 {
      background: url("/assets/images/elements/10.jpg"); }
    .element-container .element-background.element-11 {
      background: url("/assets/images/elements/11.jpg"); }
    .element-container .element-background.element-12 {
      background: url("/assets/images/elements/12.jpg"); }
    .element-container .element-background.element-13 {
      background: url("/assets/images/elements/13.jpg"); }
    .element-container .element-background.element-14 {
      background: url("/assets/images/elements/14.jpg"); }
    .element-container .element-background.element-15 {
      background: url("/assets/images/elements/15.jpg"); }
    .element-container .element-background.element-16 {
      background: url("/assets/images/elements/16.jpg"); }
    .element-container .element-background.element-17 {
      background: url("/assets/images/elements/17.jpg"); }
    .element-container .element-background.element-18 {
      background: url("/assets/images/elements/18.jpg"); }
    .element-container .element-background.element-19 {
      background: url("/assets/images/elements/19.jpg"); }
    .element-container .element-background.element-20 {
      background: url("/assets/images/elements/20.jpg"); }
    .element-container .element-background.element-21 {
      background: url("/assets/images/elements/21.jpg"); }
    .element-container .element-background.element-22 {
      background: url("/assets/images/elements/22.jpg"); }
    .element-container .element-background.element-23 {
      background: url("/assets/images/elements/23.jpg"); }
    .element-container .element-background.element-24 {
      background: url("/assets/images/elements/24.jpg"); }
    .element-container .element-background.element-25 {
      background: url("/assets/images/elements/25.jpg"); }
    .element-container .element-background.element-26 {
      background: url("/assets/images/elements/26.jpg"); }
    .element-container .element-background.element-27 {
      background: url("/assets/images/elements/27.jpg"); }
    .element-container .element-background.element-28 {
      background: url("/assets/images/elements/28.jpg"); }
    .element-container .element-background.element-29 {
      background: url("/assets/images/elements/29.jpg"); }
  .element-container .element-fade {
    position: absolute;
    width: 100%;
    height: 11rem;
    z-index: -1;
    background: linear-gradient(to bottom, transparent 0%, transparent 48%, rgba(0, 0, 0, 0.25) 94%, rgba(0, 0, 0, 0.25) 100%); }

.element-progress-days {
  width: 100%;
  height: 5px;
  display: block;
  transition: all 0.3s linear;
  overflow: hidden; }
  .lol .element-progress-days {
    background: rgba(0, 180, 177, 0.3) !important; }
  .dota .element-progress-days {
    background: rgba(223, 39, 0, 0.3) !important; }
  .csgo .element-progress-days {
    background: rgba(51, 163, 212, 0.3) !important; }
  .overwatch .element-progress-days {
    background: rgba(255, 137, 0, 0.3) !important; }
  .rocket-league .element-progress-days {
    background: rgba(160, 117, 228, 0.3) !important; }
  .valorant .element-progress-days {
    background: rgba(255, 70, 85, 0.3) !important; }
  .element-progress-days div {
    width: 0px;
    height: 5px;
    margin: 0;
    padding: 0;
    float: left;
    background: rgba(255, 255, 255, 0.6);
    animation-name: loadBar;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out; }

.element-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: .5rem .8rem;
  font-size: .9rem; }
  .element-info p {
    font-family: Montserrat !important;
    font-weight: 700 !important;
    margin: 2px 0; }
  .element-info span {
    font-family: Montserrat !important;
    font-weight: 300 !important; }

.element-title {
  width: 90%;
  text-align: center;
  padding: 0 12px;
  text-shadow: 0 0 1px #1d1d1d; }
  .element-title h4 {
    font-family: Montserrat !important;
    font-weight: 700 !important;
    font-size: 1rem;
    margin: 5px 0; }
  .element-title span {
    font-family: Montserrat !important;
    font-weight: 400 !important; }

.element-info .element-buttons {
  margin-bottom: 10px; }

.element-info .element-buttons a {
  margin: 0 25px;
  width: 240px; }
  .element-info .element-buttons a:first-child {
    background: #123235 !important; }

.element-info .element-dates {
  display: block;
  font-family: Montserrat !important;
  font-weight: 300 !important; }

.element-links {
  flex-basis: 0;
  flex-grow: 2;
  height: 150px; }
  .element-links .element-titles {
    width: 100%;
    margin-bottom: 5px; }
    .element-links .element-titles h6 {
      font-size: 1.3em;
      position: relative; }
      .element-links .element-titles h6:after {
        content: "";
        position: absolute;
        left: 25%;
        bottom: 0px;
        height: 1px;
        width: 50%;
        /* or 100px */
        border-bottom: 3px solid #00db7b;
        transform: translateY(5px); }
  .element-links .element-go {
    width: 100%;
    margin-bottom: 0; }
    .element-links .element-go .row {
      margin-bottom: 0;
      margin-right: 0; }
      .element-links .element-go .row a {
        opacity: 0.6; }
        .element-links .element-go .row a:hover {
          opacity: 1; }
        .element-links .element-go .row a .responsive-img {
          padding: 0 25px;
          pointer-events: none;
          max-height: 60px; }
  .element-links .element-extras {
    position: relative;
    top: -10px;
    margin-top: 5px; }
    .element-links .element-extras a.btn {
      background: transparent;
      border: 1px solid #112041;
      color: #123235 !important;
      margin: 5px 10px; }

.element-favourite-back {
  width: 0px;
  height: 0px;
  position: absolute;
  top: -50px;
  right: -50px;
  border: 50px solid transparent;
  border-left-color: #123235;
  transform: rotate(-45deg); }

.element-favourite {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; }
  .element-favourite > i.material-icons {
    font-size: 2em; }

.dark .element-container .element-border {
  background: white; }

.dark .element-container .element-extras a.btn {
  color: white !important; }

.element-status {
  margin: .3rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.element-icon {
  padding-right: .5rem; }

.event-header {
  align-items: stretch;
  margin-bottom: 24px; }

.img-fluid {
  pointer-events: none; }

.header-title {
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .header-title h1,
  .header-title h2 {
    text-align: left;
    margin-bottom: 0;
    overflow-wrap: break-word;
    margin-right: 48px; }
  .header-title h1 {
    font-family: Montserrat !important;
    font-weight: 700 !important; }
  .header-title h2 {
    font-family: Montserrat !important;
    font-weight: 300 !important; }

.event-overview {
  font-family: Montserrat !important;
  font-weight: 300 !important;
  width: 100%;
  border-radius: 6px;
  border-spacing: 0 .1rem;
  border-collapse: separate; }
  .event-overview tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px; }
  .event-overview tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px; }

.event-controls .col-12,
.event-controls .col-6 {
  margin-bottom: 12px; }

.module-title {
  text-align: left;
  font-weight: bold; }
  .module-title small {
    font-size: inherit;
    font-weight: 300; }
    .module-title small:not(:first-child) {
      margin-left: 12px; }

.module-title, .match-title {
  margin-bottom: 12px; }

.match-title {
  text-align: left;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 14px; }

.opacity-text {
  color: rgba(255, 255, 255, 0.6) !important; }
  .opacity-text[ng-click]:hover {
    color: white !important; }

.table-row {
  transition: background-color 0.3s linear, border-color 0.3s linar;
  margin: 0;
  text-align: center;
  padding: 12px; }
  .table-row:not(.table-header) {
    border-left: 2px solid rgba(0, 219, 123, 0.6);
    border-right: 2px solid rgba(0, 219, 123, 0.6); }
    .lol .table-row:not(.table-header) {
      border-color: rgba(0, 180, 177, 0.6) !important; }
    .dota .table-row:not(.table-header) {
      border-color: rgba(223, 39, 0, 0.6) !important; }
    .csgo .table-row:not(.table-header) {
      border-color: rgba(51, 163, 212, 0.6) !important; }
    .overwatch .table-row:not(.table-header) {
      border-color: rgba(255, 137, 0, 0.6) !important; }
    .rocket-league .table-row:not(.table-header) {
      border-color: rgba(160, 117, 228, 0.6) !important; }
    .valorant .table-row:not(.table-header) {
      border-color: rgba(255, 70, 85, 0.6) !important; }
  .table-row.inner {
    border-top: 2px solid transparent; }
    .table-row.inner.watched .col-6 {
      opacity: 0.5; }
  .table-row .col-2, .table-row .col-md-2 {
    flex-grow: 1;
    max-width: none; }
  .lol .table-row {
    background-color: rgba(18, 50, 53, 0.6) !important; }
  .csgo .table-row {
    background-color: rgba(17, 32, 65, 0.6) !important; }
  .dota .table-row {
    background-color: rgba(45, 14, 13, 0.6) !important; }
  .overwatch .table-row {
    background-color: rgba(44, 21, 10, 0.6) !important; }
  .rocket-league .table-row {
    background-color: rgba(0, 36, 22, 0.6) !important; }
  .pubg .table-row {
    background-color: rgba(11, 26, 42, 0.6) !important; }
  .table-row.titles {
    font-family: Montserrat !important;
    font-weight: 700 !important;
    font-size: 18px; }
  .table-row a[ng-href] {
    display: inline-block;
    opacity: 0.6; }
    .table-row a[ng-href]:hover {
      opacity: 1;
      cursor: pointer; }
  .table-row .mobile-header {
    display: inline-block;
    font-family: Montserrat !important;
    font-weight: 700 !important;
    font-size: 12px;
    color: #ffffff; }
  .table-row .mb-1, .table-row .mb-md-0, .table-row .mt-2 {
    display: inline-block; }
  .table-row .row.two-icons {
    justify-content: center;
    margin: 0 initial; }
    .table-row .row.two-icons .col-4 {
      padding: 0; }

.table-header {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0 6px;
  min-height: 60px;
  border-color: transparent !important;
  text-align: center;
  background: rgba(0, 219, 123, 0.6) !important; }
  .table-header.collapsed {
    border-radius: 6px;
    margin-bottom: 24px; }
  .table-header.watched.collapsed {
    opacity: 0.5; }
  .lol .table-header {
    background-color: rgba(0, 180, 177, 0.6) !important; }
  .dota .table-header {
    background-color: rgba(223, 39, 0, 0.6) !important; }
  .csgo .table-header {
    background-color: rgba(51, 163, 212, 0.6) !important; }
  .overwatch .table-header {
    background-color: rgba(255, 137, 0, 0.6) !important; }
  .rocket-league .table-header {
    background-color: rgba(160, 117, 228, 0.6) !important; }
  .valorant .table-header {
    background-color: rgba(255, 70, 85, 0.6) !important; }
  .table-header .tag,
  .table-header .tag a {
    word-break: break-word; }
  .table-header .tag {
    display: inline-block;
    font-size: 24px; }
    .table-header .tag.col-md-2 {
      font-size: 18px; }
  .table-header.collapsed a.expanded-only {
    pointer-events: none; }

.table-footer {
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-transform: uppercase;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  padding: 0 12px;
  margin-bottom: 24px;
  background: rgba(0, 219, 123, 0.6) !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; }
  .lol .table-footer {
    background: rgba(0, 180, 177, 0.6) !important; }
  .dota .table-footer {
    background: rgba(223, 39, 0, 0.6) !important; }
  .csgo .table-footer {
    background: rgba(51, 163, 212, 0.6) !important; }
  .overwatch .table-footer {
    background: rgba(255, 137, 0, 0.6) !important; }
  .rocket-league .table-footer {
    background: rgba(160, 117, 228, 0.6) !important; }
  .valorant .table-footer {
    background: rgba(255, 70, 85, 0.6) !important; }
  .table-footer a.text-emphasis img, .table-footer h6 img {
    position: relative;
    top: 3px;
    vertical-align: baseline; }
  .table-footer a.text-emphasis img {
    padding-left: 12px; }

.text-emphasis {
  display: inline-block;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff; }
  .text-emphasis.watched {
    letter-spacing: 1px; }
    .text-emphasis.watched img {
      position: relative;
      top: -1px;
      padding-right: 6px; }

.section-nav {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 24px; }

.ratings {
  font-size: 90%; }
  .ratings i:hover {
    transition: all 0.3s linear;
    cursor: pointer;
    transform: translateY(-2px); }
  .ratings.readonly i {
    pointer-events: none; }

.match-icon {
  height: 36px;
  margin: 0 6px;
  pointer-events: none; }

.team-icon {
  max-height: 48px;
  max-width: 100%;
  margin: 6px auto;
  pointer-events: none; }

.event-teams {
  font-size: 1.5rem; }

.caret-down {
  padding-right: 24px;
  background: url("/assets/images/icons/caret-down.svg");
  background-repeat: no-repeat;
  background-position: right 7px; }

.caret-up {
  padding-right: 24px;
  background: url("/assets/images/icons/caret-up.svg");
  background-repeat: no-repeat;
  background-position: right 7px; }

.teams-tabs a {
  font-family: Montserrat !important;
  font-weight: 400 !important;
  transition: all 0.3s linear;
  font-size: 18px;
  margin-right: 24px;
  padding-bottom: 3px;
  border-bottom: 2px solid transparent; }
  .teams-tabs a:hover {
    text-decoration: none !important; }
  .teams-tabs a.active {
    font-weight: bold !important; }
  .lol .teams-tabs a.active, .lol .teams-tabs a:hover {
    border-color: #00b4b1 !important; }
  .dota .teams-tabs a.active, .dota .teams-tabs a:hover {
    border-color: #df2700 !important; }
  .csgo .teams-tabs a.active, .csgo .teams-tabs a:hover {
    border-color: #33a3d4 !important; }
  .overwatch .teams-tabs a.active, .overwatch .teams-tabs a:hover {
    border-color: #FF8900 !important; }
  .rocket-league .teams-tabs a.active, .rocket-league .teams-tabs a:hover {
    border-color: #a075e4 !important; }
  .valorant .teams-tabs a.active, .valorant .teams-tabs a:hover {
    border-color: #FF4655 !important; }

.team-social a {
  display: inline-block;
  margin: 12px 24px 12px 0;
  text-decoration: none !important; }
  .team-social a img {
    pointer-events: none; }

.team-controls {
  margin-bottom: 36px !important; }

.team-controls .search-field {
  display: flex; }
  .team-controls .search-field input[type=text] {
    padding-top: 0; }
  .team-controls .search-field img {
    top: 6px; }
    .team-controls .search-field img[ng-click] {
      top: 10px; }

.team-matches-title {
  margin-bottom: 12px; }
  .team-matches-title h1 {
    display: inline;
    text-align: left; }
    .team-matches-title h1:first-child {
      margin-right: 12px;
      font-family: Montserrat !important;
      font-weight: 700 !important; }
    .team-matches-title h1:last-child {
      font-family: Montserrat !important;
      font-weight: 400 !important; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  ::-ms-track {
    margin-top: 13.4px;
    border-width: 5.4px 0; } }

.custom-player.theatre-mode #player {
  margin: auto; }
  .custom-player.theatre-mode #player .video-container {
    margin-top: 4px; }

.custom-player.theatre-mode .player-card .card-block {
  border-radius: 0 !important; }

.custom-player.theatre-mode .container {
  padding: 0; }

.custom-player .text-emphasis {
  font-size: 14px;
  text-transform: capitalize; }

.custom-player .player-card {
  background: transparent;
  border: 0; }
  .custom-player .player-card .card-footer,
  .custom-player .player-card .card-header {
    background: transparent;
    border: 0; }
  .custom-player .player-card .card-header {
    padding: 0 0 24px 0;
    font-family: Montserrat !important;
    font-weight: 700 !important; }
    .custom-player .player-card .card-header .match-event h2 {
      text-align: left;
      margin-bottom: 0; }
      .custom-player .player-card .card-header .match-event h2.light {
        margin-top: 2px;
        font-weight: 300; }
    .custom-player .player-card .card-header .player-index {
      float: right;
      text-align: left; }
      .custom-player .player-card .card-header .player-index span {
        display: inline-block; }
      .custom-player .player-card .card-header .player-index a, .custom-player .player-card .card-header .player-index span {
        margin-top: 6px; }
    .custom-player .player-card .card-header .col {
      text-align: center;
      font-size: 14px;
      line-height: 18px; }
      .custom-player .player-card .card-header .col.vs {
        flex-grow: 0;
        padding: 0 24px; }
      .custom-player .player-card .card-header .col.col-icon a {
        height: 48px;
        display: inline-block; }
        .custom-player .player-card .card-header .col.col-icon a > img {
          margin: 0; }
      .custom-player .player-card .card-header .col.next img, .custom-player .player-card .card-header .col.return img {
        position: relative;
        top: -1px;
        padding: 0 6px; }
  .custom-player .player-card .card-footer {
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0; }
    .custom-player .player-card .card-footer.controls-row {
      padding-top: 24px;
      padding-bottom: 12px; }
    .custom-player .player-card .card-footer .player-jumps a {
      font-weight: bold;
      margin-right: 36px;
      display: inline-block;
      text-transform: uppercase;
      max-width: 56px;
      text-align: center;
      vertical-align: middle; }
    .custom-player .player-card .card-footer .player-controls {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .custom-player .player-card .card-footer .player-controls .btn-skip {
        font-family: Montserrat !important;
        font-weight: 700 !important; }
        .custom-player .player-card .card-footer .player-controls .btn-skip img {
          position: relative;
          left: 2px; }
      .custom-player .player-card .card-footer .player-controls .btn-toggle {
        border-radius: 50%;
        border: 2px solid #00db7b;
        width: 48px;
        height: 48px;
        transition: all 0.3s linear; }
        .custom-player .player-card .card-footer .player-controls .btn-toggle img {
          position: relative;
          z-index: 2;
          width: 44px;
          height: 44px; }
        .lol .custom-player .player-card .card-footer .player-controls .btn-toggle {
          border-color: #00b4b1; }
        .dota .custom-player .player-card .card-footer .player-controls .btn-toggle {
          border-color: #df2700; }
        .csgo .custom-player .player-card .card-footer .player-controls .btn-toggle {
          border-color: #33a3d4; }
        .overwatch .custom-player .player-card .card-footer .player-controls .btn-toggle {
          border-color: #FF8900; }
        .rocket-league .custom-player .player-card .card-footer .player-controls .btn-toggle {
          border-color: #a075e4; }
        .valorant .custom-player .player-card .card-footer .player-controls .btn-toggle {
          border-color: #FF4655; }
        .lol .custom-player .player-card .card-footer .player-controls .btn-toggle.fill {
          background-color: #00b4b1; }
        .dota .custom-player .player-card .card-footer .player-controls .btn-toggle.fill {
          background-color: #df2700; }
        .csgo .custom-player .player-card .card-footer .player-controls .btn-toggle.fill {
          background-color: #33a3d4; }
        .overwatch .custom-player .player-card .card-footer .player-controls .btn-toggle.fill {
          background-color: #FF8900; }
        .rocket-league .custom-player .player-card .card-footer .player-controls .btn-toggle.fill {
          background-color: #a075e4; }
        .valorant .custom-player .player-card .card-footer .player-controls .btn-toggle.fill {
          background-color: #FF4655; }
    .custom-player .player-card .card-footer .player-actions img, .custom-player .player-card .card-footer .player-actions input, .custom-player .player-card .card-footer .player-actions span {
      margin-left: 24px; }
    .custom-player .player-card .card-footer .player-actions span {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      font-size: 18px;
      vertical-align: middle; }
    .custom-player .player-card .card-footer .player-actions .dropup {
      display: inline-block; }
      .custom-player .player-card .card-footer .player-actions .dropup img {
        cursor: pointer; }
      .custom-player .player-card .card-footer .player-actions .dropup .dropdown-menu {
        border-radius: 6px; }
    .custom-player .player-card .card-footer .player-actions input {
      margin-left: 0 0 0 6px;
      vertical-align: middle;
      width: 85px;
      -webkit-appearance: none;
      margin: 0 6px;
      cursor: pointer !important; }
      .custom-player .player-card .card-footer .player-actions input:focus {
        outline: none; }
      .custom-player .player-card .card-footer .player-actions input::-webkit-slider-runnable-track {
        width: 100%;
        height: 1.4px;
        box-shadow: 0px 0px 0.4px #002200, 0px 0px 0px #003c00;
        background: #ffffff;
        border-radius: 0px;
        border: 0px solid #ffffff; }
      .custom-player .player-card .card-footer .player-actions input::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #00aa00, 0px 0px 0px #00c300;
        border: 0px solid #83e584;
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background: #ffffff;
        -webkit-appearance: none;
        margin-top: -5.4px; }
      .custom-player .player-card .card-footer .player-actions input:focus::-webkit-slider-runnable-track {
        background: #ffffff; }
      .custom-player .player-card .card-footer .player-actions input::-moz-range-track {
        width: 100%;
        height: 1.4px;
        box-shadow: 0px 0px 0.4px #002200, 0px 0px 0px #003c00;
        background: #ffffff;
        border-radius: 0px;
        border: 0px solid #ffffff; }
      .custom-player .player-card .card-footer .player-actions input::-moz-range-thumb {
        box-shadow: 0px 0px 0px #00aa00, 0px 0px 0px #00c300;
        border: 0px solid #83e584;
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background: #ffffff; }
      .custom-player .player-card .card-footer .player-actions input::-ms-track {
        width: 100%;
        height: 1.4px;
        border: 0px solid transparent;
        background: transparent;
        color: transparent; }
      .custom-player .player-card .card-footer .player-actions input::-ms-fill-lower {
        background: #fcfcfc;
        border: 0px solid #ffffff;
        border-radius: 0px;
        box-shadow: 0px 0px 0.4px #002200, 0px 0px 0px #003c00; }
      .custom-player .player-card .card-footer .player-actions input::-ms-fill-upper {
        background: #ffffff;
        border: 0px solid #ffffff;
        border-radius: 0px;
        box-shadow: 0px 0px 0.4px #002200, 0px 0px 0px #003c00; }
      .custom-player .player-card .card-footer .player-actions input::-ms-thumb {
        box-shadow: 0px 0px 0px #00aa00, 0px 0px 0px #00c300;
        border: 0px solid #83e584;
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background: #ffffff;
        margin-top: 0; }
      .custom-player .player-card .card-footer .player-actions input:focus::-ms-fill-lower {
        background: #ffffff; }
      .custom-player .player-card .card-footer .player-actions input:focus::-ms-fill-upper {
        background: #ffffff; }
    .custom-player .player-card .card-footer .player-share a {
      display: block; }
      .custom-player .player-card .card-footer .player-share a:hover {
        text-decoration: none !important; }
        .custom-player .player-card .card-footer .player-share a:hover h2 {
          text-decoration: underline !important; }
    .custom-player .player-card .card-footer .player-share h2 {
      display: inline;
      text-align: right;
      margin-right: 12px;
      vertical-align: middle; }
    .custom-player .player-card .card-footer .player-share img {
      width: 30px; }
  .custom-player .player-card .card-block {
    border: 2px solid #00db7b;
    background: #192129;
    color: #00db7b;
    border-radius: 6px !important;
    box-sizing: content-box;
    overflow: hidden;
    transition: all 0.3s linear; }
    .lol .custom-player .player-card .card-block {
      border-color: #00b4b1; }
    .dota .custom-player .player-card .card-block {
      border-color: #df2700; }
    .csgo .custom-player .player-card .card-block {
      border-color: #33a3d4; }
    .overwatch .custom-player .player-card .card-block {
      border-color: #FF8900; }
    .rocket-league .custom-player .player-card .card-block {
      border-color: #a075e4; }
    .valorant .custom-player .player-card .card-block {
      border-color: #FF4655; }
    .lol .custom-player .player-card .card-block {
      color: #00b4b1; }
    .dota .custom-player .player-card .card-block {
      color: #df2700; }
    .csgo .custom-player .player-card .card-block {
      color: #33a3d4; }
    .overwatch .custom-player .player-card .card-block {
      color: #FF8900; }
    .rocket-league .custom-player .player-card .card-block {
      color: #a075e4; }
    .valorant .custom-player .player-card .card-block {
      color: #FF4655; }
    .lol .custom-player .player-card .card-block {
      background: #123235; }
    .dota .custom-player .player-card .card-block {
      background: #2d0e0d; }
    .csgo .custom-player .player-card .card-block {
      background: #112041; }
    .overwatch .custom-player .player-card .card-block {
      background: #2c150a; }
    .rocket-league .custom-player .player-card .card-block {
      background: #002416; }
    .valorant .custom-player .player-card .card-block {
      background: #0F1923; }
  .custom-player .player-card .card-block#player {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
    .custom-player .player-card .card-block#player .text-warning {
      font-family: Montserrat !important;
      font-weight: 700 !important;
      font-size: 18px; }

.custom-player #player {
  position: relative; }
  .custom-player #player .blocker {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 27%;
    width: 100%;
    background: black; }

@media only screen and (max-width: 767px) {
  .invisible {
    display: none; }
  .modal.show .modal-dialog {
    transform: none; }
  .modal-dialog {
    padding: 0;
    margin: 0; }
  .modal-content {
    padding: 24px; }
  .modal-header {
    padding: 6px; }
  .modal#event .col-12 {
    padding: 0; }
  .modal#event h5.col-12 {
    padding-left: 12px; }
  .modal#event .teams, .modal#event .staff {
    padding-bottom: 24px; }
  .modal#event .details {
    padding: 12px;
    text-align: center; }
    .modal#event .details h5.col-12 {
      padding-left: 0; }
    .modal#event .details div {
      padding-bottom: 12px;
      margin-top: 12px; }
  .modal#event .row:first-child .col-12 {
    margin-bottom: -12px; }
  .modal#event .staff-container, .modal#event .team-container {
    padding-top: 24px; }
  .col-12.header-title h1, .col-12.header-title h2 {
    text-align: center;
    margin-right: 0; }
  .table-header .tag {
    font-size: 24px; }
    .table-header .tag.vs {
      font-size: 18px; }
  .table-header .text-emphasis {
    display: inline-block;
    margin-top: 24px; }
  p.message {
    margin: 24px auto; } }

@media only screen and (max-width: 575px) {
  .contents .container, .header.container, footer.page-footer .container {
    padding: 24px !important; }
  .modal-splash, .modal-content {
    padding: 12px !important; }
  .form-group.fit {
    margin-bottom: 18px !important; }
  .social-login {
    margin: 0 auto; }
  .header.container {
    width: 100%; }
  p.message {
    margin: 12px auto; }
  footer.page-footer .container {
    padding: 12px 24px 24px 24px; }
    footer.page-footer .container .col-title {
      border: 0;
      font-weight: normal !important;
      padding-top: 12px; }
    footer.page-footer .container .quick-links a {
      display: inline-block;
      width: 50%; }
    footer.page-footer .container .patreon a {
      opacity: 1; } }

@media only screen and (max-width: 490px) {
  .static-page .card-block {
    padding: 24px; }
  .events-row {
    justify-content: center !important; }
  .logo, .navigation-logo {
    width: 149.8px;
    height: 36.4px; }
  .btn-auth {
    height: 21px;
    line-height: 17px;
    font-size: 12px;
    padding: 0 6px;
    margin: 6px 0 !important;
    display: block; }
  nav.navigation {
    margin-top: -5px; }
    nav.navigation > div {
      text-align: center; }
  .hero {
    padding: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .hero-title {
    margin-bottom: 36px;
    margin-top: 0;
    font-size: 18px; }
  .event-controls button, .event-controls .dropdown-item {
    font-size: 12px !important; }
  .table-header .tag {
    font-size: 18px; }
    .table-header .tag.vs {
      font-size: 12px; }
  .table-header .text-emphasis {
    display: inline-block;
    margin-top: 24px; } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: color 9999s, background-color 9999s ease-out !important;
  transition-delay: 9999s !important; }

@keyframes autofill {
  to {
    color: #ffffff;
    background: transparent; } }

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both; }

.text-all {
  padding-top: 0 !important;
  font-size: 14px !important; }

[data-google-query-id] {
  text-align: center; }

.space-bottom {
  margin-bottom: 24px !important; }
