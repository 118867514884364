.vm-placement {
	&:not([data-pos]){
		display: flex;
		margin: auto;
		border: 1px solid $default;
		background: $default-dark;
		align-items: center;
		justify-content: space-around;
		border: 1px solid $default;
		@include colourise-from-parent(border-color);
		@include colourise-from-parent(background, dark);
		.patreon-c2a {
			display: inline-block;
			margin-top: 6px;
			img {
				max-height: 32px;
				pointer-events: none;
			}
		}
		&[data-id="5b11556b46e0fb000159d592"], &[data-id="5b11557d46e0fb00013524dd"] {
			width: 100%;
			max-width: 728px;
			min-height: 90px;
			padding: 12px;
		}
	}
	&[data-pos] div {
		display: none;
	}
}

html, body, .contents {
	height: 100%;
}

body {
	background: $black;
	color: $white;
	font-size: 14px;
	text-align: left;
	@include montserrat-regular();
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow: 0 0 1px rgba(255, 255, 255, .3);
}

h1,
.h1 {
	font-family: Montserrat;
	font-size: 30px;
	text-align: center;
	color: $white;
}

h2,
.h2 {
	font-family: Montserrat;
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
	text-align: center;
	color: $white;
}

h3,
.h3 {
	font-family: Montserrat;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	color: $white;
}

h4,
.h4 {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	color: $white;
}
h5,
.h5 {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	color: $white;
}
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	> img {
		vertical-align: 1px;
	}
}
.light, .header-title .light {
	font-weight: 300!important;
}
a.large {
	font-family: Montserrat;
	font-size: 18px;
	font-weight: bold;
	text-align: left;
	color: $white;
}
p.large {
	font-size: 18px;
}
p.message {
	font-size: 18px;
	margin: 36px auto;
	max-width: 800px;
	a {
		color: $default;
		@include colourise-from-parent(color);
	}
}
[ng-click],
[data-toggle="tooltip"],
[data-toggle="modal"],
[du-smooth-scroll],
button[type=submit],
.btn-login, {
	cursor: pointer;
}
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
	text-decoration: initial;
}
a[ng-click]:hover {
	text-decoration: underline!important;
}

[ng-cloak],
img:not([src]),
.hide {
	display: none !important;
}
.invisible {
	visibility: hidden!important;
}
a:not(.btn) {
	color: $white;
}
.heart {
	margin-right: 5px;
	position: relative;
	top: -1px;
	&:hover {
		transform: scale(1.1);
	}
}

.force {
	&-uppercase {
		text-transform: uppercase!important;
	}
	&-lowercase {
		text-transform: lowercase!important;
	}
	&-normal {
		text-transform: none!important;
	}
}
.navigation-logo {
	display: block;
	position: relative;
	width: 214px;
	height: 52px;
}

.logo {
	position: absolute;
	left: 0;
	top: 0;
	width: 214px;
	height: 52px;
	object-fit: contain;
	pointer-events: none; // Angular animation fade
	@include transition();
	opacity: 1;
	&.ng-hide {
		opacity: 0;
	}
}


.btn-auth {
	@include montserrat-bold();
	@include transition();
	border: 2px solid $default;
	padding: 0 14px;
	height: 30px;
	line-height: 26px;
	border-radius: 6px;
	font-size: 14px;
	text-align: center;
	color: $white!important;
	background: transparent;
	@include colourise-from-parent(border-color);
	&:hover {
		color: $white;
		text-decoration: underline!important;
	}
	&:last-child {
		margin-left: 24px;
	}
	&.filled {
		color: $black!important;
		background: $default;
		@include colourise-from-parent(background);
		@include colourise-from-parent(border-color);
	}
}
.contents {
	background: $default-dark;
	@include transition(background, 0.5s, ease-in-out);
	@include colourise-from-parent(background, dark);
	.background {
		position: absolute;
		pointer-events: none;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		@include transition(opacity, 0.5s, ease-in-out);
		opacity: 1;
		&.ng-hide {
			opacity: 0;
		}
	}
	height: auto;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
	#main, .flex-filler {
		flex-grow: 1;
	}
	color: $white;
	.container {
		@include montserrat-regular();
		padding: 24px 0;
		&.header {
			padding: 36px 0 24px;
		}
	}
}
.loading-wrapper {
	vertical-align: middle;
}
.loader {
	display: inline-block;
	width: 100%;
	max-height: 100px;
	min-height: 50px;
	text-align: center;
	.loader-ev {
		@include logo-animation;
		animation-name: logo-ev;
	}
	.loader-lol {
		@include logo-animation;
		animation-name: logo-lol;
	}
	.loader-dota {
		@include logo-animation;
		animation-name: logo-dota;
	}
	.loader-csgo {
		@include logo-animation;
		animation-name: logo-csgo;
	}
	.loader-ow {
		@include logo-animation;
		animation-name: logo-ow;
	}
	.loader-valorant {
		@include logo-animation;
		animation-name: logo-valorant;
	}
}

.btn.fav-btn {
	position: relative;
	.icon {
		position: absolute;
		left: 0;
		top: 0;
		background: $default;
		@include colourise-from-parent(background);
		width: 30px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	padding-left: 42px;
}
.cookies-disclaimer, .notification-bar {
	@include transition();
	transform: translateY(0);
	opacity: 1;
	width: 100%;
	z-index: 2;
	background: $default;
	border-bottom: 0;
	color: $black;
	font-size: 14px;
	padding: 0;
	a {
		@include montserrat-bold();
		color: $black;
		&:hover {
			text-decoration: underline;
		}
	}
	border: 0;
	@include colourise-from-parent(background);
	@include colourise-from-self(background);
	.container {
		padding: 6px 24px!important;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	a.btn-success {
		background: $black;
		border: 2px $black;
		margin-left: 12px;
		font-size: 14px!important;
		color: $white!important;
		&:hover {
			text-decoration: underline!important;
		}
		border-radius: 6px;
		text-transform: uppercase;
	}

}
