// scss-lint:disable
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: color 9999s, background-color 9999s ease-out!important;
    transition-delay: 9999s!important;
}
@keyframes autofill {
    to {
        color: $white;
        background: transparent;
    }
}
input:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both;
}
.text-all {
	padding-top: 0!important;
	font-size: 14px!important;
}
// Advert placement and spacing
[data-google-query-id] {
	text-align: center;
}
.space-bottom {
	margin-bottom: 24px!important;
}
