.hero {
    width: 60%;
    text-align: center;
    margin: 0 auto;
	min-width: 260px;
	@include transition();
}

.hero-title {
    margin-bottom: 3rem;
    margin-top: 12px;
	font-size: 30px;
	min-width: 260px;
	@include transition();
	@include montserrat-light();
	letter-spacing: .25px;
}
.empty-notice {
	margin: 36px auto;
}
.load-more {
	margin: 12px auto;
}
.row.hero-icons {
	margin-bottom: 2rem;
	> div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.hero-icon {
	width: 120px;
    height: 120px;
    display: flex;
	pointer-events: none;
    flex-direction: column;
	align-items: center;
	@include transition();
	* {
		pointer-events: initial;
	}
	&:hover {
		.hero-icon__bg {
			border: 2px solid rgba(255, 255, 255, 1);
		}
		.hero-icon__icon, .hero-icon__title {
			opacity: 1;
		}
	}
	&.active {
		.hero-icon__bg {
			border: 2px solid transparent;
			@include colourise-from-parent(background, 60);
		}
		.hero-icon__icon, .hero-icon__title {
			opacity: 1;
		}

	}
}
.hero-icon__bg {
	@include transition();
	border-radius: 6px;
	width: 84px;
	height: 84px;
	display: flex;
	justify-content: center;
	align-items: center;
    border: 2px solid rgba(255, 255, 255, .4);
}
.hero-icon__icon {
    padding: .75rem;
    width: 84px;
	opacity: 0.4;
	@include transition();
}
p.hero-icon__title {
	@include transition();
	opacity: 0.4;
	margin: 0 auto;
	font-size: 14px;
	font-weight: bold;
	padding-top: .75rem;
}
.event-title {
	h2 {
		@include montserrat-bold();
		font-size: 18px;
	}
}
.search-field {
	width: 100%;
	border-bottom: 2px solid $default !important;
	position: relative;
	@include transition();
	@include colourise-from-parent(border-bottom-color, default, !important);
    input {
		top: 4px;
    }
    color: #fff;
    img {
        position: absolute;
        top: .8rem;
        right: 0rem;
		&[ng-click] {
			top: 1rem;
			transform: scale(1.5);
		}
    }

}
.events-row {
	margin-top: 36px;
}
.event-controls {
	.btn {
		padding: 6px 12px;
	}
	.d-flex {
		&.justify-content-md-start div:first-child {
			margin-right: 24px;
		}
		&.justify-content-md-end div:last-child {
			margin-left: 24px;
		}
	}
}
.full_stream_icon {
	display: inline-block;
    opacity: .6;
    text-decoration: none!important;
    margin-left: 12px;
	position: relative;
	top: -1px;
	img {
		pointer-events: none;
		height: 22px;
	}
	&:hover {
		opacity: 1;
	}
}
