.teams-tabs {
	a {
		@include montserrat-regular();
		@include transition();
		font-size: 18px;
		margin-right: 24px;
		padding-bottom: 3px;
		border-bottom: 2px solid transparent;

		&:hover {
			text-decoration: none!important;
		}
		&.active {
			font-weight: bold!important;
		}
		&.active, &:hover {
			@include colourise-from-parent(border-color, default, !important);
		}
	}
}
.team-social a {
	display: inline-block;
	margin: 12px 24px 12px 0;
	text-decoration: none!important;
	img {
		pointer-events: none;
	}
}
.team-controls {
	margin-bottom: 36px!important;
}
.team-controls .search-field {
	display: flex;
	input[type=text] {
		padding-top: 0;

	}
	img {
		top: 6px;
		&[ng-click] {
			top: 10px;
		}
	}
}
.team-matches-title {
	margin-bottom: 12px;
	h1 {
		display: inline;
		text-align: left;
		&:first-child {
			margin-right: 12px;
			@include montserrat-bold();
		}
		&:last-child {
			@include montserrat-regular();
		}
	}
}
