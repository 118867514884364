body, .modal {
	padding: 0!important;
}
.modal.show {
	display: flex!important;
	align-items: center;
	min-height: fit-content;
}
.modal-dialog {
	max-width: none;
	width: 100%;
	max-height: 100%;
	margin: 0 auto!important;
}
.modal-content {
	height: fit-content;
	border-radius: 6px;
	border: 0;
	padding: 24px;
	max-width: 945px;
	margin: 0 auto;
	background: $default-dark;
	position: relative;
	@include transition(background, 0.3s, linear);
	@include colourise-from-parent(background, dark);
}

.close {
    position: absolute;
    top: 12px;
    right: 12px;
	color: $white-40;
	z-index: 999;
    text-shadow: 0;
	&:hover {
		color: $white;
	}
}
.modal-splash {
	padding: 24px;
	h4, h5 {
		font-size: 30px;
		text-align: center;
		margin: 0 auto;
		line-height: 1.33;
	}
	h5 {
		font-weight: 300;
		margin-top: 12px;
	}
}
.modal-body {
	padding: 0;
	h5 {
		font-size: 24px;
	}
	h6 {
		@include montserrat-bold();
		font-size: 18px;
	}
}
.modal#contact.show {
	select {
		margin-top: 1px;
		background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23FFF' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
		appearance: none;
		background-size: 8px 10px;
		border: 2px solid $white;
		color: $white;
		font-weight: bold;
		padding: 0 12px;
		height: calc(2em + 2px);
		&::-ms-expand {
			display: none;
		}
		option {
			color: $white;
			background: $default-dark;
			@include transition();
			@include colourise-from-parent(background, dark);
		}
	}
	textarea {
		background: transparent;
		color: $white;
		padding: 12px;
		border-radius: 6px;
		border: 2px solid $white;
		font-weight: bold;
		&::placeholder {
			color: $white;
		}
	}
	.result {
		font-size: 18px;
	}
	form > .row {
		margin-bottom: 24px;
	}
	.col-12 > .form-group {
		margin-bottom: 8px;
	}
	.col-10 > .form-group {
		margin: 0 15px;
	}
}
